import React from "react";
import LogoLoader from "../components/Utils/LogoLoader";

const Loader = () => (
  <div
    style={{ zIndex: 2500 }}
    className="bz-absolute bz-inset-0 bz-bg-black bz-bg-opacity-50 bz-z-50 bz-flex bz-items-center bz-justify-center bz-w-auto bz-h-auto"
  >
    <LogoLoader />
  </div>
);

export default Loader;
