const app = {
  infoModal: {
    message: {
      error:
        "An error has occurred. Try again, if the problem persists, contact us",
    },
  },
  newVersion: {
    message: "New version available",
    action: "Update",
  },
};

export default app;
