const recognitions = {
  recognitions: {
    title: "Reconocimientos",
    generalResults: {
      title: "Resultados Generales",
      kpi: {
        performance: "Performance",
        teamWork: "Trabajo en equipo",
        leaderShip: "Liderazgo",
        attitude: "Actitud",
      },
      rates: {
        participationRate: "Tasa de participación",
        recognitionsDelivered: "Reconocimientos entregados",
      },
    },
    settings: {
      valuesTitle: "Usar valores por defecto",
    },
    arrowStatistics: {
      text: "que el mes pasado",
      noData: "Sin datos comparativos",
    },
    podium: {},
    userDetail: {
      comments: "Comentarios",
      noComments: "Sin comentarios aún",
    },
    ranking: {
      name: "Nombre",
      recognitions: "Reconocimientos",
      comments: "Comentarios",
      notResults: "No hay suficientes usuarios para mostrar un ranking",
      notName: "No se encuentran usuarios con ese nombre",
      withoutName: "Sin nombre",
    },
    recognitions: "reconocimientos",
    recognition: "reconocimiento",
    comments: "comentarios",
    comment: "comentario",
    seeMore: "Ver más",
    user: {
      query: {
        title: "Día de reconocimientos",
        subTitle1:
          "Es hora de reconocer el esfuerzo de tus compañer@s para motivarl@s a que sigan creciendo. ",
        subTitle2: "Puedes reconocer hasta 3 personas",
        isAnonymous: "Anónimo",
        selectUser: "Elige a quien reconocer",
        selectColaborator: "Seleccionar colaborador",
        recognitionPlaceholder: "Escribe aqui tu reconocimiento",
        recognitionType: "Este reconocimiento lo consideras:",
        cleanInfo: "Limpiar información",
      },
      report: {
        congrats: "Felicitaciones {{name}}",
        goodJob: "Excelente trabajo, mira tus reconocimientos",
        notRecognitions: "Sin reconocimientos para el mes en curso",
        notComments: "Sin commentarios para el mes en curso",
      },
      expiredToken: {
        titleRequest: "Esta solicitud de reconocimiento ha expirado",
        titleReport: "Este reporte de reconocimiento ha expirado",
        button: "Cerrar esta ventana",
      },
      error: {
        title: "Ha ocurrido un error",
        caption: "intenta de nuevo o contacta nuestro equipo de soporte",
        button: "Cerrar esta ventana",
      },
      success: {
        title: "Tus Reconocimientos han sido recibidos",
        subtitle: "",
        button: "Cerrar esta ventana",
      },
      confirmation: {
        recognitionSend: "Enviarás {{number}} de 3 reconocimientos",
        allRecognitionSend: "Enviarás 3 reconocimientos",
        warning:
          "Si continúas, no podrás enviar otro reconocimiento hasta el próximo mes",
        continueAnyway: "¿Deseas continuar de todos modos?",
        continue: "¿Deseas continuar?",
      },
      tooltip: {
        anonymity: "Perfil anónimo",
        notAnonymity: "Perfil visible",
        addRecognition: "Agregar reconocimiento",
        deleteRecognition: "Borrar reconocimiento",
        anonymityButton: "Cambia tu estado a visible",
        notAnonymityButton: "Cambia tu estado a anonimo",
      },
      validations: {
        name: "Selecciona un usuario a reconocer",
        segment: "Selecciona un valor",
        isValidate: "Hay campos obligatorios sin completar",
        mediaIsValidate: "Campos requeridos",
      },
    },
    save: "Guardar",
    send: "Enviar",
    cancel: "Cancelar",
    kpi: {
      performance: "Performance",
      teamwork: "Trabajo en equipo",
      leadership: "Liderazgo",
      attitude: "Actitud",
    },
    validations: {
      required: "Requerido *",
    },
    anonymous: "Anónimo",
    emptyState: {
      title: "No hay reconocimientos aún",
      subtitle: "Apenas haya reconocimientos se mostrarán acá",
    },
    share: {
      title: "Comparte tus reconocimientos",
      successMessage: "Reconocimiento compartido exitosamente!",
      errorMessage:
        "Error al compartir, intente mas tarde o contacte con soporte!",
      text: "Escribe un texto que quieras compartir ademas de la imagen",
      loaderText: "Esto puede tardar hasta 1 minuto...",
      additionalText:
        "Si quieres mencionar a alguien, puedes editar tu posteo desde tus publicaciones de linkedin!",
      defaultMessage:
        "Encantad@ de haber sido reconocid@ por mis compañeros y por dejar comentarios como este, que hacen que día a día quiera dar una mejor versión de mi",
      share: "Compartir",
      recognitions: "Permitir a colaboradores compartir reconocimientos",
      linkedin: "LinkedIn",
    },
    exportModal: {
      title: "Exportación de reconocimientos",
      subTitleEmail: "Ingresa un correo electrónico",
      subTitleStartDate: "Desde",
      subTitleEndDate: "Hasta",
      button: "Enviar",
      subTitleAddProperties: "Agregar propiedades",
      subTitleAddTeams: "Agregar equipos",
      tooltipEmail:
        "Se enviará un documento xlsx que contiene todos los reconocimientos enviados por sus usuarios dentro del rango de tiempo especificado. Solo puede ingresar una dirección de correo electrónico.",
      alertSuccess:
        "¡Se ha enviado con éxito! Esto podría tardar unos minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "Se requiere dirección de correo electrónico.",
        MISSING_PARAMETERS:
          "Faltan parámetros requeridos. Por favor, contacte a soporte.",
        NO_RECOGNITIONS: "No se encontraron reconocimientos para esta cuenta.",
        INVALID_DATE: "Fecha inválida.",
        NO_RECOGNITIONS_IN_DATERANGE:
          "No se encontraron reconocimientos dentro del rango de fechas.",
        INTERNAL_ERROR:
          "Ocurrió un error inesperado, por favor intente nuevamente o contacte a soporte.",
      },
    },
    exportButton: {
      title: "Exportar",
    },
    graphicExport: {
      title: "Exportar Gráfica de Reconocimiento",
      selectLabel: "Selecciona un Comentario",
      generateButton: "Generar",
      downloadButton: "Descargar",
      error: "Error al generar la imagen.",
    },
  },
};

export default recognitions;
