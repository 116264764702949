const userOnboarding = {
  userOnboarding: {
    chooseLanguage: {
      title: "Elige tu idioma",
    },
    welcomeMessage: {
      title: "¡Te damos la bienvenida a Burnout-Zero!",
      caption:
        "Construir un mejor lugar de trabajo es responsabilidad de tod@s ¡Vamos a participar!",
      anonymous: "Recuerda que tus respuestas son 100% anónimas",
      letsStart: "¡Empecemos!",
    },
    pendingMessage: {
      title: "¡Bienvenid@ a bordo! ⚓",
      caption:
        "Cuéntanos cómo ha sido tu experiencia en estos primeros días y ayúdanos a crear el mejor lugar para trabajar",
    },
    onboardingMessage: {
      title: "¡Ya pasó un mes! ¿Cómo te has sentido? 🔎",
      caption:
        "Cuéntanos cómo ha sido tu proceso de onboarding y cómo te sientes para alcanzar el éxito en tu organización",
    },
    subtitles: {
      multipleChoice: "Selecciona una opción",
      draggableScale:
        "Donde 1 estrella significa {{lowValue}} y 5 significan {{highValue}}",
      opinionScale:
        "Donde 0 significa {{lowValue}} y 10 significa {{highValue}}",
      text: "Deja tu comentario",
      default: "Selecciona una opción",
    },
    starOption: {
      labels: {
        feedback: {
          first: "Absolutamente no",
          second: "No",
          third: "Lo justo",
          fourth: "Si",
          fifth: "Absolutamente",
        },
        peersRelationship: {
          first: "Para nada",
          second: "No",
          third: "Neutral",
          fourth: "Si",
          fifth: "Absolutamente",
        },
        onboardingSurvey: {
          first: "Para nada bien",
          second: "Mal",
          third: "Neutral",
          fourth: "Bien",
          fifth: "Absolutamente bien",
        },
      },
    },
    faceOption: {
      labels: {
        0: "Muy en desacuerdo",
        1: "En desacuerdo",
        2: "Neutral",
        3: "De acuerdo",
        4: "Muy de acuerdo",
      },
    },
    stepperButtons: {
      next: "Siguiente",
      finish: "Finalizar",
    },
    acceptOur: "Al finalizar, estás aceptando nuestros",
    termsAndConditions: "términos, condiciones y privacidad.",
    finishMessage: {
      title: "¡Muchas gracias por tus respuestas!",
      subTitle:
        "Solo un último paso para finalizar. De estas 10 métricas, indícanos cuales son las 3 que consideras más importantes en tu trabajo",
      finishButton: "Finalizar",
      finalMessage:
        "¡Agradecemos tus respuestas, ya eres parte de Burnout-Zero!",
      closeMessage: "Ya puedes cerrar esta ventana",
      closeButton: "Cerrar esta ventana",
    },
    comment: "En base a tu respuesta anterior, ¿nos puedes contar un poco más?",
    error: "Esta invitación ya expiró",
  },
};

export default userOnboarding;
