const login = {
  login: {
    slackSso: "Ingresa con Slack",
    googleSso: "Ingresa con Google",
    msteamsSso: "Ingresa con Teams",
    title: "Ingresa a tu cuenta",
    user: "Email",
    password: "Contraseña",
    forgotPassword: "¿Olvidaste tu contraseña?",
    button: "Ingresar a tu cuenta",
    required: "*Requerido",
    checkErrors: "Revisa lo siguiente:",
    resetPassword: {
      title: "Recupera tu contraseña",
      button: "Recuperar contraseña",
      succesful: {
        title: "Estamos listos",
        checkEmail:
          "Consulta en tu correo electronico las instrucciones para cambiar tu contraseña",
        button: "Iniciar sesión",
        again:
          "Recibir nuevamente las instrucciones para cambiar tu contraseña",
      },
    },
    changePassword: {
      title: "Crea tu nueva contraseña",
      password: "Contraseña",
      confirm: "Confirma tu contraseña",
      button: "Cambiar contraseña",
      passwordScores: {
        tooShort: "Muy corta",
        weak: "Debil",
        insufficient: "Insuficiente",
        good: "Buena",
        strong: "Fuerte",
        veryStrong: "Muy fuerte",
      },
      success: {
        title: "La contraseña ha sido cambiada exitosamente",
        login: "Iniciar sesión",
      },
      failure: {
        invalid: "Token inválido",
        token:
          "El token para restaurar la contraseña ha expirado o es no es válido.",
        instructions:
          "Recibir nuevamente las instrucciones para cambiar tu contraseña",
      },
      manualReset: {
        title: "Cambia tu contraseña",
        explanation:
          "Ahora eres administrador, por favor cambia la contraseña de tu cuenta",
        newPassword: "Nueva contraseña",
        logoutExplanation:
          "Luego de confirmar el cambio, serás redirigido para iniciar tu sesión con tu nueva contraseña.",
      },
    },
  },
};

export default login;
