const feedbacks = {
  feedbacks: {
    title: "Feedback",
    active: "Ativas",
    archived: "Arquivadas",
    archivedFeedback: {
      see: "Ver feedback",
      delete: "Excluir feedback",
    },
    table: {
      header: {
        received: "Recebido",
        area: "Área",
        type: "Tipo",
        status: "Status",
        comments: "Comentários",
      },
    },
    askForFeedback: "Solicitar feedback",
    allMembers: "Todos os colaboradores",
    modal: {
      title: "Solicite feedback para suas equipes",
      formTitle: "Equipe",
      success: "Solicitações enviadas com sucesso",
      error: "Erro ao enviar a solicitação, tente novamente",
    },
    comment: {
      placeholder: "Escreva um comentário",
      success: "Seu comentário foi salvo",
      close: "Fechar esta janela",
      useDescription:
        "Este comentário será apenas para uso interno, nenhum comentário será enviado ao colaborador sobre o feedback",
    },
    statuses: {
      received: "Recebido",
      reviewed: "Em andamento",
      resolved: "Resolvido",
      archived: "Arquivado",
    },
    options: {
      archived: "Arquivar",
      reopen: "Reabrir",
      delete: "Excluir",
    },
    types: {
      all: "Todos os feedbacks",
      positive: "😀 Positivos",
      neutral: "😐 Neutros",
      constructive: "🤔 Construtivos",
      complaint: "✋ Ajuda",
    },
    category: {
      choose: "Selecione a categoria",
      edit: "Editar categoria",
    },
    detail: {
      comments: "Comentários",
      title: "Detalhes do feedback",
      success: "Feedback atualizado com sucesso!",
      error: "Erro ao atualizar o feedback. Tente novamente",
      required: "Obrigatório *",
    },
    filters: {
      startText: "Data inicial",
      endText: "Data final",
      category: "Categoria",
      type: "Tipo de feedback",
    },
    metadata: {
      positive: "Positivo",
      neutral: "Neutro",
      constructive: "Construtivo",
    },
    user: {
      title: "Olá",
      subTitle: "Queremos saber sua opinião sobre a empresa.",
      caption:
        "Conte-nos um pouco sobre as coisas que você gostaria que melhorassem na empresa e se tiver alguma ideia ou iniciativa.",
      privacy: "Lembre-se de que sua resposta será mantida 100% anônima",
      opinionPlaceholder: "Escreva sua opinião aqui",
      categoryPlaceholder: "Você considera esta opinião como:",
      send: "Enviar",
      types: {
        positive: "Positiva",
        neutral: "Neutra",
        constructive: "Construtiva",
      },
      success: {
        close: "Fechar esta janela",
        title: "Sua opinião foi recebida",
        subTitle:
          "A pessoa responsável estará revisando sua opinião. Todas as opiniões são levadas em consideração.",
      },
      expired: {
        title: "Esta solicitação de feedback expirou",
        close: "Fechar esta janela",
      },
      error: {
        title: "Ocorreu um erro",
        caption:
          "Tente novamente ou entre em contato com nossa equipe de suporte.",
        close: "Fechar esta janela",
      },
    },
    multipleSelect: {
      archiveAll: "Arquivar todos",
      deselectAll: "Desmarcar todos",
    },
  },
};

export default feedbacks;
