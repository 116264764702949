const userParticipation = {
  user_participation: {
    hello: "Hello",
    title: "The time has come to answer your pending questions!",
    beginTitle: "Begin",
    expired: "This token already expired or is not found",
    alreadyUsed:
      "This survey has already been answered, please wait for the next one with the same eagerness that you wait for the weekend",
    unhandledError:
      "An error has occurred. Try again, if the problem persists, contact us",
    empty: "No pending questions",
    close: "Close this window",
    success_title: "Your answers were saved successfully",
  },
};

export default userParticipation;
