import { colors, createTheme } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      dark: "#EFF0F0",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#161A26",
      1: "#292e3c",
      2: "#454A55",
      3: "#727681",
      4: "#a1a5ae",
    },
    black: {
      1: "#292E3C",
      2: "#454A55",
      3: "#727681",
      4: "#A1A5AE",
    },
    purple: {
      main: "#2d162f",
      1: "#342036",
      2: "#433145",
      3: "#5B4B5C",
      4: "#867987",
    },
    secondary: {
      main: "#D6FEFE",
    },
    lightBlue: {
      1: "#E2FFFF",
      2: "#EBFFFF",
      3: "#F4FFFF",
      4: "#FCFFFF",
    },
    lightGrey: {
      main: "#EFF0F0",
      1: "#F5F5F5",
      2: "#FFFFFF",
    },
    accents: {
      lightBlue: {
        main: "#57ACFC",
        1: "#7BBFFF",
        2: "#a0d1ff",
        3: "#C1E1FF",
        4: "#E7F3FF",
      },
      purple: {
        main: "#7877F7",
        1: "#8C8BF4",
        2: "#A2A1F2",
        3: "#B7B6F1",
        4: "#D3D3F4",
      },
    },
    warning: {
      main: "#F9D204",
      secondary: "#FCE25F",
      light: "#fff0a3",
    },
    success: {
      main: "#03C87B",
      secondary: "#4CDFA5",
      light: "#8BEBC5",
    },
    error: {
      main: "#E83F12",
      secondary: "#F87248",
      light: "#F5A48A",
    },
    info: {
      main: "#2196f3",
    },
    text: {
      primary: "#161a26",
      secondary: "#a1a5ae",
    },
    graph: {
      0: "#6929c4",
      1: "#9f1853",
      2: "#198038",
      3: "#b28600",
      4: "#8a3800",
      5: "#1192e8",
      6: "#fa4d56",
      7: "#009d9a",
      8: "#005d5d",
      9: "#570408",
    },
  },
  shadows,
  typography,
});

export default theme;
