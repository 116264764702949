const filterData = {
  filterData: {
    overall: "Geral",
    organization: "Toda a organização",
    noInfo: {
      team: "Não há informações de equipes.",
      segment: "Não há informações de segmentos.",
    },
    disabled: {
      activeUsers: "(< 4 usuários ativos)",
    },
    sections: {
      all: "Todos",
      teams: "Equipes",
      segments: "Segmentos",
    },
    periods: {
      "7d": "7 dias",
      "30d": "30 dias",
      "6m": "6 meses",
      "12m": "12 meses",
    },
  },
};

export default filterData;
