const adminOnboarding = {
  adminOnboarding: {
    welcomeMessage: {
      title: "Bem-vindo(a) ao Burnout-Zero!",
      caption:
        "No próximo passo, vamos configurar sua conta para que você possa aproveitar ao máximo nossa plataforma.",
      startButton: "Vamos lá!",
    },
    importEmployees: {
      slack: "Conectar com Slack",
      slackCodeError:
        "O código do Slack expirou ou é inválido. Tente novamente.",
      teams: "Conectar com Teams",
      excel: "Usar planilha de importação",
      slackConnectTitle:
        "Convide seus colaboradores selecionando seu canal do Slack",
      slackCustomConfigurationTitle: "Personalize suas configurações",
      slackProTip:
        "*Dica: Use o canal #geral para importar todos os colaboradores de sua empresa. Além disso, sempre que uma nova pessoa se juntar ao seu espaço de trabalho, ela receberá automaticamente um convite para participar.",
      emailDisplay: {
        title:
          "Percebemos que você ocultou os endereços de e-mail nas configurações do Slack.",
        dontWorry: "Não se preocupe, aqui está",
        thisGuide: "este guia",
        toMakeSure:
          "para garantir que todos os colaboradores possam participar. Depois de alterar as configurações, você pode concluir o processo de configuração.",
      },
    },
    sendPreferences: {
      sendSurveys: "Enviar pesquisas por",
    },
    sendSchedule: {
      configurationAlert:
        "Recomendamos a seguinte configuração. Fique à vontade para alterá-la como desejar.",
      frequencies: {
        onceADay: "1 vez ao dia",
        onceAWeek: "1 vez por semana",
        twiceAWeek: "2 vezes por semana",
      },
      timesAWeek: "Selecione os dias",
      daysAWeek: "Quais dias? Selecione",
      schedule: "Em qual horário?",
      questionQuantity: "Quantidade de perguntas a serem enviadas",
    },
    createPassword: {
      scoreWords: {
        weak: "Fraca",
        insufficient: "Insuficiente",
        good: "Boa",
        strong: "Forte",
        veryStrong: "Muito forte",
        shortScoreWord: "Muito curta",
      },
      password: {
        input: "Digite sua senha",
        placeholder: "Digite sua senha",
        confirm: "Confirmar senha",
        placeholder2: "Digite sua senha novamente",
      },
      alert: {
        questions:
          "Após a conclusão deste processo, um questionário de 8 perguntas será enviado aos colaboradores adicionados.",
        seeQuestions: "Ver perguntas",
        additionaly: "Além disso, você está concordando com nossos",
        termsAndConditions: "termos e condições e política de privacidade.",
      },
    },
    excelImport: {
      error: {
        title: "Ops! Não foi possível carregar seu arquivo",
        button: "Tente novamente",
      },
      success: {
        title: "Seu arquivo foi enviado",
        subtitle:
          "Seu arquivo foi carregado com sucesso. Verifique o status de cada colaborador.",
        button: "Continuar",
      },
      dragFile: "Arraste seu arquivo aqui ou",
      loadFile: "Carregar arquivo",
      loadCollaborators: "Carregar colaboradores",
      title: "Importar colaboradores",
      fileFormat:
        "Para convidar seus colaboradores, faça o upload de um arquivo .xlsx com o seguinte formato:",
      downloadFile: "Baixar arquivo",
      loading: "Carregando",
    },
    optionalSteps:
      "Você pode pular as etapas opcionais e configurar isso posteriormente",
    stepperButtons: {
      back: "Voltar",
      next: "Próximo",
      finish: "Concluir",
    },
    finishMessage: {
      loading: "Estamos finalizando tudo",
      success: "Sua conta foi configurada com sucesso!",
      logIn: "Fazer login",
      error: "Não foi possível configurar sua conta",
      tryAgain: "Tentar novamente",
    },
    questions: {
      title: "Perguntas",
    },
  },
};

export default adminOnboarding;
