const app = {
  infoModal: {
    message: {
      error:
        "Ocorreu um erro. Tente novamente, se o problema persistir, entre em contato conosco",
    },
  },
  newVersion: {
    message: "Nova versão disponível",
    action: "Atualizar",
  },
};

export default app;
