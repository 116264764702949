const imports = {
  import: {
    dragOrselectImage: {
      lightBackground: "fundo claro",
      darkBackground: "fundo escuro",
    },
  },
};

export default imports;
