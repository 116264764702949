import {
  GET_FEEDBACKS,
  OPEN_FEEDBACK,
  NEW_FEEDBACK,
  NEW_CONVERSATION,
} from "../actions/types";

const initialState = {
  success: false,
  message: null,
  feedbacks: null,
  openFeedback: {
    isOpen: false,
    id: null,
  },
};

export default function feedbackReducers(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACKS:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        feedbacks: action.payload.feedbacks,
      };
    case OPEN_FEEDBACK:
      return {
        ...state,
        openFeedback: {
          isOpen: true,
          id: action.payload,
        },
      };
    case NEW_FEEDBACK:
      return {
        ...state,
        feedbacks: [action.payload.savedFeedback, ...state.feedbacks],
        openFeedback: {
          isOpen: true,
          id: action.payload.savedFeedback._id,
        },
      };
    case NEW_CONVERSATION:
      return {
        ...state,
        feedbacks: state.feedbacks.map((f) =>
          f._id === action.payload.feedback._id ? action.payload.feedback : f
        ),
      };
    default:
      return state;
  }
}
