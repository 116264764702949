import { createStyles, makeStyles } from "@material-ui/core";
import { palette } from "burnout-zero-ds";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      "@global": {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        h4: {
          margin: "0.2rem",
        },
        html: {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          height: "100vh",
          width: "100vw",
        },
        body: {
          backgroundColor: "#f4f6f8",
          height: "100vh",
          width: "100vw",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100vh",
          width: "100vw",
        },
        ".MuiTabs-indicator": {
          backgroundColor: palette.bluebonnet[200],
        },
        ".divider": { margin: "30px" },
        ".MuiModal-root": {
          backgroundColor: "none",
        },
        ".MuiTableCell-root": {
          border: "none",
        },
        ".MuiOutlinedInput-root": {
          borderRadius: "4px",
        },
        ".MuiSelect-root": {
          fontWeight: 400,
          fontSize: 16,
        },
        ".MuiMenuItem-root": {
          fontSize: "12px",
        },
        ".primaryButton": {
          backgroundColor: theme.palette.accents.lightBlue.main,
          color: theme.palette.lightGrey[2],
          fontSize: 12,
          minWidth: 100,
          padding: 10,
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.accents.lightBlue[2],
          },
        },
        ".secondaryButton": {
          backgroundColor: "#E7F3FF",
          color: "#57ACFC",
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        ".outlinedButton": {
          textTransform: "none",
          border: "1px white solid",
          color: "white",
          fontSize: 12,
          minWidth: 100,
          padding: 8,
          margin: 10,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.lightGrey[2],
          },
        },
        ".MuiTableCell-body": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
        },
        ".metric-chip": {
          minWidth: "100%",
          margin: "5px 0",
          justifyContent: "space-between",
        },
        ".metric-chip-active": {
          opacity: 0.8,
          minWidth: "100%",
          margin: "5px 0",
          justifyContent: "space-between",
        },
        ".metric-0 .MuiSvgIcon-root": {
          fill: "#FFEA48",
        },
        ".metric-1 .MuiSvgIcon-root": {
          fill: "#FABB01",
        },
        ".metric-2 .MuiSvgIcon-root": {
          fill: "#F25930",
        },
        ".metric-3 .MuiSvgIcon-root": {
          fill: "#0B3D6F",
        },
        ".metric-4 .MuiSvgIcon-root": {
          fill: "#0081C7",
        },
        ".metric-5 .MuiSvgIcon-root": {
          fill: "#48BCD7",
        },
        ".metric-6 .MuiSvgIcon-root": {
          fill: "#3C2B61",
        },
        ".metric-7 .MuiSvgIcon-root": {
          fill: "#FF4F7E",
        },
        ".metric-8 .MuiSvgIcon-root": {
          fill: "#58A360",
        },
        ".metric-9 .MuiSvgIcon-root": {
          fill: "#E3071D",
        },
        ".MuiChip-colorPrimary": {
          backgroundColor: theme.palette.accents.lightBlue.main,
        },
        ".MuiStepIcon-root.MuiStepIcon-active": {
          color: theme.palette.accents.purple.main,
        },
        ".tableHeaders": {
          // margin: "20px 0",
        },
        ".multicolor-bar": {
          margin: "20px 0",
        },
        ".multicolor-bar .bars .bar": {
          float: "left",
          height: 10,
        },
        ".multicolor-bar .bars div.bar:first-of-type": {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
        ".multicolor-bar .bars div.bar:last-of-type": {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },
        ".MuiFormLabel-root": {
          [theme.breakpoints.down(1280)]: {
            fontSize: "6px",
          },
          [theme.breakpoints.between(1280, 1550)]: {
            fontSize: "10px",
          },
          [theme.breakpoints.up(1550)]: {
            fontSize: "12px",
          },
        },
        ".Mui-TableContefault GlobalStyles;ainer .MuiTableCell-body": {
          fontSize: 12,
        },
        ".MuiTabs-root": {
          "& .MuiSvgIcon-root": {
            color: palette.bluebonnet[400],
          },
        },
      },
    }),
  { index: 1 }
);

const GlobalStyles = function () {
  useStyles();

  return null;
};

export default GlobalStyles;
