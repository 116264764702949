const slack = {
  slack: {
    permissions: {
      update:
        "Você precisa atualizar suas permissões para usar esta funcionalidade. Não se preocupe, vamos facilitar para você:",
      button: "Atualizar",
    },
    channel: {
      select: "Escolha seu canal de entrega",
    },
  },
};

export default slack;
