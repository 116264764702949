const buttons = {
  buttons: {
    create: "Criar",
    accept: "Aceitar",
    continue: "Continuar",
    cancel: "Cancelar",
    add: "Adicionar",
    back: "Voltar",
    save: "Salvar",
    saveChanges: "Salvar alterações",
    next: "Próximo",
    finish: "Finalizar",
    send: "Enviar",
    schedule: "Agenda",
  },
};

export default buttons;
