const slack = {
  slack: {
    permissions: {
      update:
        "You need to update your permissions to use this functionality. Don't worry, we'll make it easy for you:",
      button: "Update",
    },
    channel: {
      select: "Choose your delivery channel",
    },
  },
};

export default slack;
