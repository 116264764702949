/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable unused-imports/no-unused-vars */
import { NavigationClient } from "@azure/msal-browser";

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
class CustomNavigationClient extends NavigationClient {
  constructor(history) {
    super();
    this.history = history;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url, options) {
    return false;
  }
}

export default CustomNavigationClient;
