const organizationTeams = {
  organizationTeams: {
    searchTeamPlaceholder: "Search team",
    searchUserPlaceholder: "Search employee",
    totalCollaborators: "Total employees: ",
    totalManagers: "Total managers: ",
    totalTeams: "Total teams: ",
    teamCreationError: "Error creating team",
    managerAssignmentError: "Error adding manager",
    addManagerTitle: "Add manager",
    selectedManager: "Selected manager",
    assignManager: "Assign manager",
    teamDeletionError: "Error deleting team",
    deleteTeamTitle: "Delete team",
    deleteTeamCaption:
      "Are you sure you want to delete this team? Child teams will be deleted",
    deleteLabel: "Delete",
    selectAllMember: "Select all",
    addMembersError: "Error adding members",
    addCollaboratorsTitle: "Add employee",
    teamLabel: "Team",
    teamNamePlaceholder: "Team name",
    collaboratorsLabel: "Employees",
    collaboratorsPlaceholder: "Select employees",
    addCollaboratorsCaption: "Add employees",
    moveTeam: "Move team {{team}}",
    moveTeamLabel: "Move team",
    parentTeamLabel: "Parent team",
    editTeamError: "Error editing team",
    editTeamTitle: "Edit team",
    toEditTeamLabel: "Team name",
    editTeamCaption: "Edit team",
    importTeamCaption: "Import teams",
    importFileExtension: "File format must be .xlsx",
    importFileAgainCaption: "Upload file again",
    dropFileCaption: "Drag and drop your file here or",
    loadFileCaption: "Load file",
    importCollaboratorsTitle: "Import your employees",
    importCollaboratorsSubtitle:
      "To invite your employees upload an .xlsx file with the desired format",
    downloadTemplateFile: "Download file",
    allTeamsTitle: "All teams",
    teamsTitle: "Teams",
    editTeamOption: "Edit team",
    addManagerOption: "Add manager",
    addCollaboratorsOption: "Add employees",
    moveToAnotherTeamOption: "Move to another team",
    moveTeamOption: "Move team",
    deleteTeamOption: "Delete team",
    deleteUserOption: "Delete member",
    selectedCollaboratorsPlural: "{{amount}} selected employees",
    selectedCollaboratorsSingular: "{{amount}} selected employee",
    moveToAnotherTeamAnchor: "Move to another team",
    deleteCollaboratorsAnchor: "Delete employees",
    deselectAll: "Deselect all",
    createTeamsAnchor: "Create teams",
    fileError:
      "Error: this file has errors, please review it and upload it again",
    viewErrorsAnchor: "View errors",
    uploadAgainAnchor: "Upload file again",
    fileWithoutErrorLabel: "File uploaded without errors",
    viewImportedDataAnchor: "View imported data",
    uploadAnotherFileAnchor: "Upload another file",
    importResultsTitle: "Import results",
    importErrorsAmount: "{{amount}} found errors",
    uploadFileAgainCaption: "Upload again",
    reviewUploadErrorsCaption:
      "Review the result of your import so you can correct any errors.",
    rowCell: "Row",
    emailCell: "Email",
    teamCell: "Team",
    roleCell: "Role",
    nameCell: "Name",
    managerCell: "Manager(s)",
    collaboratorsCell: "Employees",
    deleteMemberError: "Error deleting member",
    deleteSingleMember: "Delete {{roleName}}",
    deleteSingleMemberCaption: "Delete",
    deleteMemberConfirmation: "Are you sure to delete {{user}} from {{team}}",
    deleteMultipleMember: "Delete {{amount}} users from this team",
    deleteMembersConfirmation:
      "Are you sure to delete the selected users from {{team}}",
    deleteMultipleMemberCaption: "Delete",
    moveSingleMemberTitle: "Move {{user}}",
    moveMemberConfirmation:
      "Are you sure to move this {{roleName}} from {{team}}?",
    selectTeamLabel: "Select team",
    selectTeamPlaceholder: "New team",
    moveMemberLabel: "Move",
    moveMemberError: "Error moving member",
    moveMembersError: "Error moving members",
    moveMultipleMembersTitle: "Move {{amount}} users to another team",
    moveMultipleMembersConfirmation:
      "Are you sure to move the selected users from {{team}}",
    employeeRoleName: "Employee",
    managerRoleName: "Manager",
    singularCollaborator: "employee",
    multipleCollaborators: "employees",
    singularManager: "manager",
    multipleManagers: "managers",
    assignResultsTitle: "Employees assignment results",
    assignResultsSubtitle: "Please check your assignment results",
    assignmentResultsClose: "Close",
    assigmentSuccessfully: "Employee assignment successful",
    assigmentNotPerformed: "Assignment no performed",
  },
};

export default organizationTeams;
