const recognitions = {
  recognitions: {
    title: "Recognitions",
    generalResults: {
      title: "General Results",
      kpi: {
        performance: "Performance",
        teamWork: "Teamwork",
        leaderShip: "Leadership",
        attitude: "Attitude",
      },
      rates: {
        participationRate: "Participation rate",
        recognitionsDelivered: "recognitions delivered",
      },
    },
    settings: {
      valuesTitle: "Use default values",
    },
    arrowStatistics: {
      text: "than last month",
      noData: "without comparative data",
    },
    podium: {},
    userDetail: {
      comments: "Comments",
      noComments: "No comments yet",
    },
    ranking: {
      name: "Name",
      recognitions: "Recognitions",
      comments: "Comments",
      notResults: "There aren't enough users to show information",
      notName: "No users found with that name",
      withoutName: "Withour name",
    },
    recognitions: "recognitions",
    recognition: "recognition",
    comments: "comments",
    comment: "comment",
    seeMore: "See more",
    user: {
      query: {
        title: "Recognitions day",
        subTitle1:
          "It's time to recognize the effort of your colleagues to motivate them and keep them growing.",
        subTitle2: "You can recognize up to 3 people.",
        isAnonymous: "Anonymous",
        selectUser: "Choose who to recognize",
        selectColaborator: "Select coworker",
        recognitionPlaceholder: "Write your recognition here",
        recognitionType: "This recognition do you consider:",
        cleanInfo: "Clean information",
      },
      report: {
        congrats: "Congratulations {{name}}",
        goodJob: "Excellent job. Take a look at your recognitions.",
        notRecognitions: "No recognition for the current month",
        notComments: "No comment for the current month",
      },
      expiredToken: {
        titleRequest: "This recognition request has expired",
        titleReport: "This recognition report has expired",
        button: "Close this window",
      },
      error: {
        title: "An error has occurred",
        caption: "try again or contact our support team",
        button: "Close this window",
      },
      success: {
        title: "Your recognitions have been received",
        subtitle: "",
        button: "Close this window",
      },
      confirmation: {
        recognitionSend: "You will send {{number}} out of 3 recognitions",
        allRecognitionSend: "You will send 3 recognitions",
        warning:
          "If you continue, you won't be able to send another recognition until next month.",
        continueAnyway: "Do you want to continue anyway?",
        continue: "Do you want to continue?",
      },
      tooltip: {
        anonymity: "Anonymous profile",
        notAnonymity: "Visible profile",
        addRecognition: "Add recognition",
        deleteRecognition: "delete reconognition",
        anonymityButton: "Alter your anonymity setting to visible",
        notAnonymityButton: "Alter your anonymity setting to anonimous",
      },
      validations: {
        name: "Choose an user to recognize",
        segment: "Choose a segment",
        isValidate: "There are required fields that must be completed",
        mediaIsValidate: "Required fields",
      },
    },
    save: "Save",
    send: "Send",
    cancel: "Cancel",
    kpi: {
      performance: "Performance",
      teamwork: "Teamwork",
      leadership: "Leadership",
      attitude: "Attitude",
    },
    validations: {
      required: "Required *",
    },
    anonymous: "Anonymous",
    emptyState: {
      title: "No recognitions yet",
      subtitle:
        "We will post any recognitions as soon as they become available",
    },
    share: {
      title: "Share your recognitions",
      successMessage: "Recognition successfully shared!",
      errorMessage: "Error sharing, please try again later or contact support!",
      text: "Write a message you want to share along with the image",
      loaderText: "This may take up to 1 minute...",
      additionalText:
        "If you want to mention someone, you can edit your post from your LinkedIn posts!",
      defaultMessage:
        "Thrilled to have been recognized by my peers and for leaving comments like this, which make me want to be a better version of myself every day",
      share: "Share",
      recognitions: "Allow employees to share recognitions",
      linkedin: "LinkedIn",
    },
    exportModal: {
      title: "Export recognitions",
      subTitleEmail: "Enter an email",
      subTitleStartDate: "From",
      subTitleEndDate: "To",
      button: "Send",
      subTitleAddProperties: "Add properties",
      subTitleAddTeams: "Add teams",
      tooltipEmail:
        "An xlsx document will be sent containing all the recognitions sent by your users within the specified time range. You can only enter one email address.",
      alertSuccess:
        "It has been sent successfully! This may take a few minutes.",
      sending: "Sending...",
      error: {
        NO_EMAIL: "Email address is required.",
        MISSING_PARAMETERS:
          "Missing required parameters. Please contact support.",
        NO_RECOGNITIONS: "No recognitions found for this account.",
        INVALID_DATE: "Invalid date.",
        NO_RECOGNITIONS_IN_DATERANGE:
          "No recognitions found within date range.",
        INTERNAL_ERROR:
          "Unexpected error occured, please try again or contact support.",
      },
    },
    exportButton: {
      title: "Export",
    },
    graphicExport: {
      title: "Export Recognition Graphic",
      selectLabel: "Select a Comment",
      generateButton: "Generate",
      downloadButton: "Download",
      error: "Failed to generate image.",
    },
  },
};

export default recognitions;
