import jwtDecode from "jwt-decode";
import isEmpty from "is-empty";
import {
  SET_CURRENT_USER,
  USER_LOADING,
  PASSWORD_RESETED,
  PASSWORD_CHANGED,
} from "../actions/types";

const initialState = {
  loading: false,
  isAuthenticated: !!localStorage.getItem("jwtToken"),
  user: localStorage.getItem("jwtToken")
    ? jwtDecode(localStorage.getItem("jwtToken"))
    : {},
  isPasswordReseted: false,
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_RESETED:
      return {
        ...state,
        isPasswordReseted: true,
      };
    case PASSWORD_CHANGED:
      return {
        ...state,
        isPasswordChanged: true,
      };
    default:
      return state;
  }
}
