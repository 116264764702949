import React from "react";
import styled, { keyframes } from "styled-components";
import organizationLogo from "../../../assets/images/organizationLogo.svg";

const logoloader = keyframes`
0% {
  transform: scale(1);
}  
50% {
  transform: scale(2);

`;

const Loader = styled.img`
  animation: ${logoloader} 1s ease-in-out infinite;
  border-radius: 50%;
  display: inline-block;
  height: 6rem;
  width: 6rem;
`;

const LogoLoader = function () {
  return <Loader src={organizationLogo} />;
};

export default LogoLoader;
