import { SET_CURRENT_ACCOUNT } from "../actions/types";

const initialState = {
  current: {},
  featureFlags: {},
};

export default function accountReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ACCOUNT:
      return {
        current: action.payload.account,
        featureFlags: action.payload.featureFlags,
      };
    default:
      return state;
  }
}
