const adminOnboarding = {
  adminOnboarding: {
    welcomeMessage: {
      title: "¡Te damos la bienvenida a Burnout-Zero!",
      caption:
        "Dentro del siguiente paso a paso configuraremos tu cuenta para que le saques el máximo provecho a nuestra plataforma.",
      startButton: "¡Empecemos!",
    },
    importEmployees: {
      slack: "Conéctate con Slack",
      slackCodeError:
        "El código de Slack ha caducado o es inválido, intenta nuevamente",
      teams: "Conéctate con Teams",
      excel: "Usa una planilla de carga",
      slackConnectTitle:
        "Invita a tus colaboradores seleccionando tu canal de Slack",
      slackCustomConfigurationTitle: "Customiza tus configuraciones",
      slackProTip:
        "*Pro tip: Usa el canal #general para importar a todos los colaboradores de tu empresa. Adicionalmente, cada vez que una persona nueva se una a tu espacio de trabajo se le enviará una invitación automática a participar.",
      emailDisplay: {
        title:
          "Hemos detectado que tienes los correos ocultos en los ajustes de Slack.",
        dontWorry: "No te preocupes, aquí te dejamos",
        thisGuide: "esta guía",
        toMakeSure:
          "para asegurarte que todos los colaboradores puedan participar. Una vez que hayas cambiado los ajustes puedes terminar el onboarding.",
      },
    },
    sendPreferences: {
      sendSurveys: "Enviar encuestas por",
    },
    sendSchedule: {
      configurationAlert:
        "Te recomendamos la siguiente configuración. Sientete en libertad de cambiarla como desees.",
      frequencies: {
        onceADay: "1 vez al día",
        onceAWeek: "1 vez a la semana",
        twiceAWeek: "2 veces a la semana",
      },
      timesAWeek: "Selecciona qué días",
      daysAWeek: "¿Qué días? selecciona",
      schedule: "¿En qué horario?",
      questionQuantity: "Cantidad de preguntas a enviar",
    },
    createPassword: {
      scoreWords: {
        weak: "Débil",
        insufficient: "Insuficiente",
        good: "Buena",
        strong: "Fuerte",
        veryStrong: "Muy fuerte",
        shortScoreWord: "Muy corta",
      },
      password: {
        input: "Ingresa tu contraseña",
        placeholder: "Escribe tu contraseña",
        confirm: "Confirma contraseña",
        placeholder2: "Escribe de nuevo tu contraseña",
      },
      alert: {
        questions:
          "Al finalizar este proceso se mandara una encuesta de 8 preguntas a los colaboradores adjuntos.",
        seeQuestions: "Ver las preguntas",
        additionaly: "Adicionalmente, estás aceptando nuestros",
        termsAndConditions: "términos, condiciones y privacidad.",
      },
    },
    excelImport: {
      error: {
        title: "Ups! no hemos podido cargar tu archivo",
        button: "Reintentar",
      },
      success: {
        title: "Se ha subido tu archivo",
        subtitle:
          "Tu archivo se ha cargado correctamente. Revisa el estado de cada colaborador.",
        button: "Continuar",
      },
      dragFile: "Arrastra tu archivo acá o",
      loadFile: "Cargar archivo",
      loadCollaborators: "Carga tus colaboradores",
      title: "Importa tus colaboradores",
      fileFormat:
        "Para invitar a tus colaboradores sube un archivo .xlsx con el siguiente formato:",
      downloadFile: "Descargar archivo",
      loading: "Cargando",
    },
    optionalSteps:
      "Siempre te puedes saltar los pasos opcionales y configurar esto más tarde",
    stepperButtons: {
      back: "Atrás",
      next: "Siguiente",
      finish: "Finalizar",
    },
    finishMessage: {
      loading: "Estamos dejando todo listo",
      success: "¡Tu cuenta ya está configurada correctamente!",
      logIn: "Iniciar sesión",
      error: "No se ha podido configurar tu cuenta",
      tryAgain: "Intentar de nuevo",
    },
    questions: {
      title: "Preguntas",
    },
  },
};

export default adminOnboarding;
