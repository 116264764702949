const userParticipation = {
  user_participation: {
    hello: "Olá",
    title: "É hora de responder suas perguntas pendentes!",
    beginTitle: "Começar",
    expired: "O token expirou ou não foi encontrado",
    alreadyUsed:
      "Este questionário já foi respondido. Aguarde o próximo com a mesma ansiedade que aguarda o fim de semana.",
    unhandledError:
      "Ocorreu um erro. Tente novamente. Se o problema persistir, entre em contato conosco.",
    empty: "Não há perguntas pendentes",
    close: "Fechar esta janela",
    success_title: "Suas respostas foram salvas com sucesso",
  },
};

export default userParticipation;
