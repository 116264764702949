const recognitions = {
  recognitions: {
    title: "Reconhecimentos",
    generalResults: {
      title: "Resultados Gerais",
      kpi: {
        performance: "Performance",
        teamWork: "Trabalho em equipe",
        leaderShip: "Liderança",
        attitude: "Atitude",
      },
      rates: {
        participationRate: "Taxa de participação",
        recognitionsDelivered: "Reconhecimentos entregues",
      },
    },
    settings: {
      valuesTitle: "Usar valores padrão",
    },
    arrowStatistics: {
      text: "em relação ao mês passado",
      noData: "Sem dados comparativos",
    },
    podium: {},
    userDetail: {
      comments: "Comentários",
      noComments: "Sem comentários ainda",
    },
    ranking: {
      name: "Nome",
      recognitions: "Reconhecimentos",
      comments: "Comentários",
      notResults: "Não há usuários suficientes para exibir um ranking",
      notName: "Não foram encontrados usuários com esse nome",
      withoutName: "Sem nome",
    },
    recognitions: "reconhecimentos",
    recognition: "reconhecimento",
    comments: "comentários",
    comment: "comentário",
    seeMore: "Ver mais",
    user: {
      query: {
        title: "Dia de Reconhecimentos",
        subTitle1:
          "É hora de reconhecer o esforço de seus colegas para motivá-los a continuarem crescendo.",
        subTitle2: "Você pode reconhecer até 3 pessoas",
        isAnonymous: "Anônimo",
        selectUser: "Selecione quem reconhecer",
        selectColaborator: "Selecionar colaborador",
        recognitionPlaceholder: "Escreva aqui o seu reconhecimento",
        recognitionType: "Você considera este reconhecimento como:",
        cleanInfo: "Limpar informações",
      },
      report: {
        congrats: "Parabéns, {{name}}",
        goodJob: "Excelente trabalho, veja seus reconhecimentos",
        notRecognitions: "Sem reconhecimentos para o mês atual",
        notComments: "Sem comentários para o mês atual",
      },
      expiredToken: {
        titleRequest: "Esta solicitação de reconhecimento expirou",
        titleReport: "Este relatório de reconhecimento expirou",
        button: "Fechar esta janela",
      },
      error: {
        title: "Ocorreu um erro",
        caption:
          "tente novamente ou entre em contato com nossa equipe de suporte",
        button: "Fechar esta janela",
      },
      success: {
        title: "Seus Reconhecimentos foram recebidos",
        subtitle: "",
        button: "Fechar esta janela",
      },
      confirmation: {
        recognitionSend: "Você enviará {{number}} de 3 reconhecimentos",
        allRecognitionSend: "Você enviará 3 reconhecimentos",
        warning:
          "Se você continuar, não poderá enviar outro reconhecimento até o próximo mês",
        continueAnyway: "Deseja continuar mesmo assim?",
        continue: "Deseja continuar?",
      },
      tooltip: {
        anonymity: "Perfil anônimo",
        notAnonymity: "Perfil visível",
        addRecognition: "Adicionar reconhecimento",
        deleteRecognition: "Excluir reconhecimento",
        anonymityButton: "Mudar seu status para visível",
        notAnonymityButton: "Mudar seu status para anônimo",
      },
      validations: {
        name: "Selecione um usuário para reconhecer",
        segment: "Selecione um valor",
        isValidate: "Campos obrigatórios não preenchidos",
        mediaIsValidate: "Campos obrigatórios",
      },
    },
    save: "Salvar",
    send: "Enviar",
    cancel: "Cancelar",
    kpi: {
      performance: "Performance",
      teamwork: "Trabalho em equipe",
      leadership: "Liderança",
      attitude: "Atitude",
    },
    validations: {
      required: "Obrigatório *",
    },
    anonymous: "Anônimo",
    emptyState: {
      title: "Ainda não há reconhecimentos",
      subtitle: "Assim que houver reconhecimentos, eles serão exibidos aqui",
    },
    share: {
      title: "Compartilhe seus reconhecimentos",
      successMessage: "Reconhecimento compartilhado com sucesso!",
      errorMessage:
        "Erro ao compartilhar, tente novamente mais tarde ou entre em contato com o suporte!",
      text: "Escreva uma mensagem que você queira compartilhar junto com a imagem",
      loaderText: "Isso pode levar até 1 minuto...",
      additionalText:
        "Se quiser mencionar alguém, você pode editar sua postagem a partir de suas publicações no LinkedIn!",
      defaultMessage:
        "Feliz por ter sido reconhecid@ por meus colegas e por deixar comentários como este, que me fazem querer ser uma versão melhor de mim mesm@ a cada dia",
      share: "Compartilhar",
      recognitions:
        "Permitir que os colaboradores compartilhem reconhecimentos",
      linkedin: "LinkedIn",
    },
    exportModal: {
      title: "Exportando reconhecimentos",
      subTitleEmail: "Digite um e-mail",
      subTitleStartDate: "Desde",
      subTitleEndDate: "Até",
      button: "Enviar",
      subTitleAddProperties: "Adicionar propriedades",
      subTitleAddTeams: "Adicionar equipes",
      tooltipEmail:
        "Um documento xlsx será enviado contendo todas as confirmações enviadas por seus usuários dentro do intervalo de tempo especificado. Só é possível inserir um endereço de e-mail.",
      alertSuccess: "Foi enviado com sucesso! Isso pode levar alguns minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "É necessário um endereço de e-mail.",
        MISSING_PARAMETERS:
          "Faltam parâmetros necessários. Por favor, entre em contato com o suporte.",
        NO_RECOGNITIONS: "No se encontraron reconocimientos para esta cuenta.",
        INVALID_DATE: "Invalid date.",
        NO_RECOGNITIONS_IN_DATERANGE:
          "No se encontraron reconocimientos dentro del rango de fechas.",
        INTERNAL_ERROR:
          "Ocorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
      },
    },
    exportButton: {
      title: "Exportar",
    },
    graphicExport: {
      title: "Exportar Gráfico de Reconhecimento",
      selectLabel: "Selecionar um Comentário",
      generateButton: "Gerar",
      downloadButton: "Baixar",
      error: "Falha ao gerar a imagem.",
    },
  },
};

export default recognitions;
