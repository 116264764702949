const video = {
  video: {
    notFound: "Este vídeo está em construção!",
    sections: {
      dashboard: "Dashboard",
      users: "Usuários",
      teams: "Equipes",
      surveys: "Pesquisas",
      feedback: "Feedbacks",
      questions: "Perguntas",
      recognitions: "Reconhecimentos",
      settings: "Configurações",
      properties: "Propriedades",
      segments: "Segmentos",
      calendar: "Calendário",
      organization_teams: "Equipes",
      user_feedback: "Feedback",
      performance: "Performance",
    },
  },
};

export default video;
