const adminOnboarding = {
  adminOnboarding: {
    welcomeMessage: {
      title: "Welcome to Burnout-Zero!",
      caption:
        "Within the following step by step we will configure your account so that you get the most out of our platform.",
      startButton: "Let's start!",
    },
    importEmployees: {
      slack: "Connect via Slack",
      slackCodeError: "Code error, it is invalid or has expired, try again",
      teams: "Connect via Teams",
      excel: "Use a loading sheet",
      slackConnectTitle:
        "Invite your collaborators by selecting your Slack channel",
      slackCustomConfigurationTitle: "Custom configurations",
      slackProTip:
        "*Pro tip: Use the #general channel to import all the collaborators of your company. Additionaly, whenever a new person joins your workspace they will be sent an invitation to participate automatically.",
      emailDisplay: {
        title:
          "We have detected that you have hidden emails in the settings of Slack.",
        dontWorry: "Dont worry, here we leave you",
        thisGuide: "this guide",
        toMakeSure:
          "to ensure that all collaborators can participate. Once you have changed the settings you can finish your onboarding.",
      },
    },
    sendPreferences: {
      sendSurveys: "Send surveys by",
    },
    sendSchedule: {
      configurationAlert:
        "We recommend you the following configuration. Feel free to change it as you wish.",
      frequencies: {
        onceADay: "Once a day",
        onceAWeek: "Once a week",
        twiceAWeek: "Twice per week",
      },
      timesAWeek: "Select which days",
      daysAWeek: "Which days? select",
      schedule: "In which schedule?",
      questionQuantity: "Amount of questions sent",
    },
    createPassword: {
      scoreWords: {
        weak: "Weak",
        insufficient: "Insufficient",
        good: "Good",
        strong: "Strong",
        veryStrong: "Very strong",
        shortScoreWord: "Too short",
      },
      password: {
        input: "Enter password",
        placeholder: "Write your password",
        confirm: "Confirm your password",
        placeholder2: "Write your password again",
      },
      alert: {
        questions:
          "At the end of this process, a 8-question survey will be sent to attached collaborators.",
        seeQuestions: "See the questions",
        additionaly: "Additionally, you're accepting our",
        termsAndConditions: "terms and conditions and privacy.",
      },
    },
    excelImport: {
      error: {
        title: "Oops! We weren't able to load your file",
        button: "Retry",
      },
      success: {
        title: "Your file has been uploaded",
        subtitle:
          "Your file was loaded correctly. Check the status of your collaborators.",
        button: "Continue",
      },
      dragFile: "Drag your file here or",
      loadFile: "Load file",
      loadCollaborators: "Load your collaborators",
      title: "Import your collaborators",
      fileFormat:
        "To invite your collaborators upload an .xlsx file with the following format:",
      downloadFile: "Download file",
      loading: "Loading",
    },
    optionalSteps:
      "You can always skip the optional steps and configure this later",
    stepperButtons: {
      back: "Back",
      next: "Next",
      finish: "Finish",
    },
    finishMessage: {
      loading: "We are leaving everything ready",
      success: "Your account is ready!",
      logIn: "Log in",
      error: "There was an error configuring your account",
      tryAgain: "Try again",
    },
    questions: {
      title: "Questions",
    },
  },
};

export default adminOnboarding;
