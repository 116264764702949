const users = {
  users: {
    overview: {
      income: "Income: {{income}}",
      daysAgo: "{{days}} days ago",
      daysLeft: "{{days}} days left until your birthday",
      birthday: "Date of Birth: {{birthday}}",
      role: "Role: {{role}}",
      personalInfo: "Personal Information",
      employmentInformation: "Employment Information",
      required: "Required *",
      userType: "User Type",
      permissions: "Permissions",
      tabSwitcher: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Contact Number",
        country: "Location",
        birthDate: "Date of Birth",
        emergencyContactName: "Emergency Contact (Name)",
        emergencyContactPhoneNumber: "Emergency Contact (Number)",
        role: "Role",
        area: "Area",
        hiringDate: "Hiring Date",
        correlative: "Unique Employee Number (UEN)",
        contractType: "Contract Type",
        salary: "Salary",
        teams: "Teams",
        hisManager: "Manager in charge: ",
      },
      tabs: {
        summary: "Summary",
        properties: "Properties",
        activity: "Activity",
        settings: "Settings",
      },
    },
    allReminder: "Are you sure want to send a reminder to all inactive users?",
    notResults: "There were no results. Try changing your search",
    userDelete: "Are you sure to delete the user?",
    userReminder: "Are you sure want to send a reminder to the user?",
    invitationSelect: "Choose an invitation form",
    admin: "Administrator",
    employee: "Employee",
    manager: "Manager",
    type: "Type",
    onboardingType: {
      initial: "Are you sure you want to send the initial survey?",
      onboarding: "Are you sure you want to send the onboarding survey?",
    },
    card: {
      withoutName: "Nameless",
      active: "Active",
      inactive: "Inactive",
      pending: "Pending",
      onboarding: "Onboarding",
      deactivated: "Deactivated",
      activation: "Activation",
      all: "All",
      tooltip: "Send a reminder for the user to activate their account",
      tooltipRMAdd: "Include it in the participation of future random meetings",
      tooltipRMRemove:
        "Exclude from the participation in future random meetings",
      tooltipActivation:
        "Choose the type of invitation for the user to activate their account",
    },
    edit: {
      title: "Edit user",
      required: "Fields with * are required.",
      names: "First Name",
      lastNames: "Last name",
      email: "Email *",
      birthday: "Birthday",
      anniversary: "Work anniversary",
      receiveSurvey: "Available to receive surveys",
      success: "User updated successfully!",
      errors: {
        email: "Required *",
        update: "Error updating the user, try again.",
      },
      properties: {
        title: "Properties",
        name: "Property name",
        value: "Value",
      },
      genders: {
        gender: "Gender",
        male: "Male",
        female: "Female",
        other: "Other",
      },
    },
    delete: {
      title:
        "To keep improving your experience, please tell us why you are eliminating this user",
      subtitle: "Choose a subcategory",
      confirm: "Confirm",
      cancel: "Cancel",
      categories: {
        voluntary: {
          text: "Resignation",
          subcategories: {
            OPPORTUNITY: "Different job opportunity",
            PERSONAL_PROBLEMS: "Personal problems",
            STRESS: "Stress",
            CULTURE: "No cultural fit",
            MUTUAL_AGREEMENT: "Mutual agreement",
          },
        },
        dismissal: {
          text: "Dismissal",
          subcategories: {
            END: "End of Term",
            RESTRUCTURING: "Internal restructuring",
            PERFORMANCE: "Low performance",
            AREA_CLOSURE: "Area closure",
            CULTURE: "No cultural fit",
          },
        },
        other: {
          text: "Other",
          subcategories: {
            DUPLICATE: "Duplicate",
            GENERIC_EMAIL: "Generic email",
            INCORRECT_EMAIL: "Incorrect email",
            DOES_NOT_WORK_HERE_ANYMORE: "Doesn't work here anymore",
          },
        },
      },
    },
    header: {
      title: "Users",
      total: "Total ({{total}})",
      actives: "Actives ({{actives}})",
      inactives: "Inactives ({{inactives}})",
      deactivateds: "Deactivated ({{deactivateds}})",
      pending: "Pendings ({{pendings}})",
      onboarding: "Onboardings ({{onboardings}})",
      admins: "Admins ({{admins}})",
      selectStatus: "Select a status",
      searchPlaceholder: "Search employee",
      inviteMembers: "Invite",
      integrateToMsTeamsInBatch: "Integrate users to Microsoft Teams",
      inviteTooltip: "Send a reminder to all your inactive collaborators",
    },
    table: {
      user: "User",
      status: "Status",
      participation: "Activities",
    },
    email: {
      title: "Invite to your team",
      subTitle:
        "The collaborator will receive a link to activate their account",
      helperText: "Invalid email",
      placeholder: "name@company.com",
      button: "Send Invitation",
    },
    slack: {
      title: "Invite your collaborators by selecting your Slack channel",
      loadingText: "Loading...",
      noOptions: "Without options",
      reminder:
        "*Important: Invitations will only be sent to those users who do not exist in your account.",
      invite: {
        successful: "Invitations sent successfully",
        noInvitations:
          "No invitations were sent. Please check the selected channel.",
      },
      submit: {
        title:
          "Invitations will be sent to {{members}} channel partners {{name}}",
        loading: "We are leaving everything ready",
        invite: "¡Invite!",
      },
    },
    excel: {
      import: {
        teamSummary: {
          header: {
            team: "Team",
            comments: "Comments",
          },
          statuses: {
            ok: "OK",
            teamAlreadyExist: "Team already exists",
            errorSavingTeam: "Error saving team",
            teamNameEmpty: "Team without name",
            teamWithoutManager: "Team without manager",
            userWithTeam: "User alredy have team",
            unexistentUser: "User doesn't exist in the account",
          },
        },
        userSummary: {
          header: {
            row: "Row",
            email: "Email",
            comments: "Comments",
          },
          statuses: {
            ok: "OK",
            errorSavingUser: "Unexpected error creating collaborator",
            userAlreadyExist: "Employee already exists",
            mailNotFound: "Field email is required",
            invalidEmail: "Field email is invalid",
          },
        },
        usersTitle: "Import your employees",
        usersLoad: "Load your employees",
        teamsTitle: "Import yourt teams",
        teamsLoad: "Load your teams",
        users: "employees",
        user: "employee",
        teams: "teams",
        team: "team",
        drag: "Drag here",
        download: "Download template",
        emptyText:
          "{{errorCount}} {{errorType}} could not be imported, check the detail here",
        successText: "Import successfully {{successCount}} {{type}}",
        errorText:
          "Import successfully {{successCount}} {{successType}}. But, {{errorCount}} {{errorType}} could not be imported, check the detail here",
      },
      success: {
        title: "Your file has been uploaded",
        button: "Continue",
      },
      error: {
        title: "Oops! we couldn't upload your file",
        subTitle: "Check your internet connection or that the file is correct.",
        button: "Try again",
      },
    },
    msTeamsInvite: {
      title: "Invite by microsoft teams",
      subTitle: "Import to your team from your Teams channel",
      adminConsent:
        "Please provide admin consent to integrate into microsoft teams",
      grantConsent: "Grant consent",
      inviteForm: {
        title: "You are almost ready!",
        subTitle:
          "Invite your collaborators by selecting your Microsoft teams channel",
        proTip: "*Pro tip: All members of the selected team will be invited",
        disclaimer:
          "Invitations will be sent to team {{groupCount}} collaborators",
        lightDisclaimer:
          "Invitations will be sent to all {{team}} team collaborators",
        onProgress: "We are leaving everything ready",
        onProgressGroups: "We are getting account's team list",
        submit: "Invite!",
      },
    },
    invite: {
      slack: {
        title: "Invite by Slack",
        subtitle: "Import to your team from your Slack channel",
      },
      excel: {
        title: "Invite by excel",
        subtitle: "Invite multiple team members in one go",
      },
      email: {
        title: "Invite by email",
        subtitle: "Slow down a bit and invite your team by mail",
      },
      type: {
        initial: {
          title: "Initial Survey",
          description:
            "An initial survey with 8 questions will be sent for account activation.",
        },
        onboarding: {
          title: "Onboarding Survey for New Collaborators",
          description:
            "An initial survey about your recruitment process and a monthly onboarding survey will be sent.",
        },
      },
      channel: "Select the delivery method",
      invitationType: "Select the invitation type",
    },
    cardActions: {
      viewProfile: "View profile",
      edit: "Edit user",
      reminder: "Send reminder",
      msTeams: "Integrate to Microsoft Teams",
      msTeamsIntegrateTitle: "Integrating to Microsoft Teams",
      msTeamsIntegrateSuccess: "Successfully integrated!",
      msTeamsIntegrateClose: "Close",
      delete: "Delete user",
    },
    multipleSelect: {
      deselectAll: "Deselect all",
      deleteAll: "Delete all",
      tooltipDeleteAll:
        "All selected users will be deleted with the same reason",
      communications: "Edit activity",
    },
    communications: {
      title: "Participation preferences",
      general: "Participation general",
      titleSwitch:
        "Edit the participants' involvement in the following activities",
      randomMeeting: "Random meeting",
      feedback: "Feedback",
      customSurvey: "Custom survey",
      microSurvey: "Microsurvey",
      recognitionRequest: "Recognition request",
      participate: {
        in: "Participate in {{eventType}}",
        diableUntil: "Disable participation until",
      },
      holiday: "Absent from activities",
    },
  },
};

export default users;
