const newDashboard = {
  newDashboard: {
    title: "Relatórios",
    header: {
      titles: {
        generals: "Resultados Gerais",
        participation: "Taxa de ativação",
        bozEnps: "BOZ & eNPS",
        dimentions: "Resultados por Dimensão",
        metricsTime: "Métricas ao Longo do Tempo",
        comparisons: "Comparações",
      },
      randomMeetingModal: {
        title: "Random meeting",
        tooltipTitle:
          "Reunião coordenada: Pelo menos uma pessoa respondeu ao quebra-gelo",
        meetingTableTitle: "A reunião foi coordenada?",
        captainsTableTitle: "Capitães",
        placeholderSearch: "Procurar Capitão...",
        captain: "Capitão",
        participant: "Participante",
        buttons: {
          subtitlebutton1: "Redondo",
          subtitlebutton2: "Estado",
        },
        buttonsContent1: {
          option: "Redondo",
        },
        buttonsContent2: {
          option1: "Todos",
          option2: "Coordenada",
          option3: "Pendente",
        },
        loading: "Carregando...",
      },
    },
    generalResults: {
      title: "Resultados Gerais",
      activeUsers: {
        title: "Usuários Ativos",
        text: "na semana passada",
      },
      group: {
        title: "Equipes",
        text: "Última atualização:",
      },
      userFeedback: {
        title: "Feedback 1:1",
        text: "Recebidos no último mês",
      },
      newFeedbacks: {
        title: "Novos Feedbacks",
        text: "na semana passada",
      },
      activeSurveys: {
        title: "Pesquisas Ativas",
        text: "na semana passada",
      },
      noPreviousPeriodData: "sem dados da semana passada",
      noCurrentPeriodData: "sem dados desta semana",
      tooltip: "Ver mais",
    },
    activationRate: {
      title: "Taxa de Ativação",
      circleChart: "Ativação",
      tooltip:
        "A taxa de ativação é a relação entre usuários ativos / total de usuários.",
      noHistoricData: "Sem dados históricos",
      noCurrentData: "Sem dados de ativação para o ciclo atual",
      noData: "Sem dados históricos e de ativação para o ciclo atual",
      seeMore: "Ver mais",
    },
    bozRate: {
      title: "Índice BOZ",
      subtitle: "Média de todas as perguntas",
      tooltip:
        "O índice BOZ é a média de todas as perguntas respondidas para o ciclo e equipe/segmento selecionado. Além disso, você pode ver quantas perguntas foram enviadas para o ciclo atual.",
      svg: {
        semiCircle: {
          critical: "Crítico",
          veryLow: "Muito Baixo",
          low: "Baixo",
          good: "Bom",
          veryGood: "Muito Bom",
          extraordinary: "Extraordinário",
          admirable: "Admirável",
        },
        arrowStatistics: {
          text: "em relação ao período anterior",
          noPreviousData: "Sem dados do ciclo anterior",
          noCurrentData: "Sem dados deste ciclo",
        },
        line: {
          ask: "Perguntas enviadas",
          outOf: "de",
        },
        lineWithoutAsk: "Sem dados de perguntas",
      },
    },
    eNPS: {
      title: "eNPS",
      statistics: {
        promoters: "Promotores ",
        passives: "Passivos ",
        detractors: "Detratores ",
      },
      tooltip:
        "O Net Promoter Score (eNPS) reflete a probabilidade de os membros de sua equipe recomendarem sua organização como um bom lugar para trabalhar. A pontuação (de -100 a 100) é calculada como (promotores - detratores) / número total de colaboradores * 100",
      svg: {
        tooltip: {
          detractors: "Detratores",
          passives: "Passivos",
          promoters: "Promotores",
        },
        arrowStatistics: {
          text: "em relação ao período anterior",
          noPreviousData: "sem dados do ciclo anterior",
          noCurrentData: "ainda não medido o eNPS deste ciclo",
        },
        goodBenckmark: "Acima do benchmark das empresas do Burnout-Zero",
        badBenckmark: "Abaixo do benchmark das empresas do Burnout-Zero",
        iqualBenchmark: "Benchmark acima da média das empresas do Burnout-Zero",
        noBenchmarkData: "Ainda não medido o benchmark",
      },
      commentsModal: {
        title: "Comentários detratores",
        subtitle: "Anônimo",
      },
      benchBoz: "Benchmark BOZ",
    },
    dimensionMetrics: {
      title: "Resultados por Dimensão",
      tooltip:
        "Você pode explorar as informações clicando na dimensão ou na própria barra de pontuação. Para retornar a um nível superior, clique em qualquer lugar do gráfico",
      noResults: "Sem informações para este ciclo",
    },
    metricsTime: {
      title: "Métricas ao Longo do Tempo",
      tooltip:
        "O valor de uma métrica é calculado como uma média móvel de 90 dias. Por exemplo, o valor para a data atual é uma média de todas as respostas dos últimos 90 dias",
      noMetricsTime: "Sem informações para este ciclo",
    },
    participation: {
      label: "Participação",
    },
    comparisons: {
      title: "Comparações",
      tooltip:
        "Você pode comparar as pontuações de equipes ou segmentos em um mapa de calor. Selecione as informações que deseja comparar no filtro",
      emptyStates: {
        teams: "Selecione uma ou várias equipes para ver a comparação",
        segments: "Selecione um ou vários segmentos para ver a comparação",
      },
      heatmap: {
        lowest: "Pontuação mais baixa",
        highest: "Pontuação mais alta",
      },
      header: {
        title: {
          teams: "Equipes",
          segments: "Segmentos",
        },
        compare: "Comparar",
        select: "Selecionar",
        filter: "Filtrar",
      },
      checkboxSelect: {
        selected: "Selecionados",
        noInfo: "Nenhuma informação disponível.",
      },
      allSelect: {
        allSelect: "Selecionar tudo",
      },
    },
    privacyMessage: {
      title:
        "Para manter a privacidade e o anonimato de seus colaboradores, é necessário ter um mínimo de 4 usuários ativos.",
      subTitle:
        "Você precisa de um total de {{number}} usuários ativos para usar o painel.",
    },
    filterCycle: {
      currentCycle: "Ciclo Atual (#{{cycle}})",
      pastCycle: "Ciclo #{{cycle}}",
    },
    recognitions: {
      title: "Agradecimentos",
      subTitle: "Recebido no último mês",
    },
  },
};

export default newDashboard;
