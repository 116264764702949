import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Snackbar, Zoom } from "@material-ui/core";
import { Alert } from "burnout-zero-ds";

function TransitionDown(props) {
  return (
    <div>
      <Zoom {...props} />
    </div>
  );
}
const InfoModal = ({ open, type, text }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (!open) {
    return <></>;
  }

  return (
    <Snackbar
      open={open}
      onClose={() => dispatch({ type: "CLOSE_INFO" })}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionComponent={TransitionDown}
    >
      <Alert
        elevation={6}
        severity={type}
        variant="outlined"
        onClose={() => dispatch({ type: "CLOSE_INFO" })}
      >
        {text === "defaultText" ? t("infoModal.message.error") : text}
      </Alert>
    </Snackbar>
  );
};

// type: error success warning
InfoModal.defaultProps = {
  open: false,
  type: "error",
  text: "defaultText",
};

InfoModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
};

export default InfoModal;
