const userOnboarding = {
  userOnboarding: {
    chooseLanguage: {
      title: "Choose your language",
    },
    welcomeMessage: {
      title: "We welcome you to Burnout-Zero!",
      caption:
        "Building a better workplace is everyone's responsibility. Let's participate!",
      anonymous: "Remember your answers are 100% anonymous",
      letsStart: "Let's start",
    },
    pendingMessage: {
      title: "Welcome aboard! ⚓",
      caption:
        "Tell us about your experience in these first days and help us create the best workplace.",
    },
    onboardingMessage: {
      title: "It's been a month already! How have you been feeling? 🔎",
      caption:
        "Tell us about your onboarding process and how you're gearing up for success in your organization.",
    },

    subtitles: {
      multipleChoice: "Select an option",
      draggableScale:
        "Where 1 star means {{lowValue}} and 5 means {{highValue}}",
      opinionScale: "Where 0 means {{lowValue}} and 10 means {{highValue}}",
      text: "Leave your comment",
      default: "Select an option",
    },
    starOption: {
      labels: {
        feedback: {
          first: "Absolutely not",
          second: "No",
          third: "Just fair",
          fourth: "Yes",
          fifth: "Absolutely",
        },
        peersRelationship: {
          first: "Not at all",
          second: "No",
          third: "Neutral",
          fourth: "Yes",
          fifth: "Absolutely",
        },
        onboardingSurvey: {
          first: "Not at all good",
          second: "Bad",
          third: "Neutral",
          fourth: "Good",
          fifth: "Absolutely good",
        },
      },
    },
    faceOption: {
      labels: {
        0: "Strongly disagree",
        1: "Disagree",
        2: "Neutral",
        3: "Agree",
        4: "Strongly agree",
      },
    },
    stepperButtons: {
      next: "Next",
      finish: "Finish",
    },
    acceptOur: "By finishing, you are accepting our",
    termsAndConditions: "terms, conditions and privacy policy",
    finishMessage: {
      title: "Thank you for your answers!",
      subTitle:
        "Just last one step. From this 10 metrics, tell us which are the 3 you consider the most importants at your work",
      finishButton: "Finish",
      finalMessage:
        "We appreciate your answers, you are already part of Burnout-Zero!",
      closeMessage: "You can close this window now",
      closeButton: "Close this window",
    },
    comment:
      "Could you give us your input on how to improve? Your feedback is valuable to us",
    error: "This invitation has already been expired",
  },
};

export default userOnboarding;
