const feedbacks = {
  feedbacks: {
    title: "Feedback",
    active: "Active",
    archived: "Archived",
    archivedFeedback: {
      see: "See feedback",
      delete: "Delete feedback",
    },
    table: {
      header: {
        received: "Received",
        area: "Area",
        type: "Type",
        status: "Status",
        comments: "Comments",
      },
    },
    askForFeedback: "Ask for feedback",
    allMembers: "All members",
    modal: {
      title: "Request feedback to your teams",
      formTitle: "Team",
      success: "Request sent successfully",
      error: "Error sending request, try again.",
    },
    comment: {
      placeholder: "Write a comment",
      success: "We have saved your comment",
      close: "Close this window",
      useDescription:
        "This comment is for internal use only. None of this comments will be sent to the employee",
    },
    statuses: {
      received: "Received",
      reviewed: "Reviewed",
      resolved: "Resolved",
    },
    options: {
      archived: "Archived",
      reopen: "Reopen",
      delete: "Delete",
    },
    types: {
      all: "All opinions",
      positive: "😀 Positive",
      neutral: "😐 Neutral",
      constructive: "🤔 Constructive",
      complaint: "✋ Help",
    },
    category: {
      choose: "Choose category",
      edit: "Edit category",
    },
    detail: {
      title: "Opinion's detail",
      success: "Feedback updated successfully!",
      comments: "Comments",
      error: "Error updating feedback, try again.",
      required: "Required *",
    },
    filters: {
      startText: "Start date",
      endText: "End date",
      category: "Category",
      type: "Opinion's type",
    },
    metadata: {
      positive: "Positive",
      neutral: "Neutral",
      constructive: "Constructive",
    },
    user: {
      title: "Hello",
      subTitle: "We want to know your opinion about your company.",
      caption:
        "Tell us a little about the things you would like to improve in your company and if you have any ideas or initiatives.",
      privacy: "Remember your answer will remain 100% anonymous",
      opinionPlaceholder: "Write your opinion here",
      categoryPlaceholder: "Do you consider this opinion:",
      send: "Send",
      types: {
        positive: "Positive",
        neutral: "Neutral",
        constructive: "Constructive",
      },
      success: {
        close: "Close this window",
        title: "Your opinion has been received",
        subTitle:
          "The person in charge will be reviewing your opinion. All opinions are taken into account.",
      },
      expired: {
        title: "This feedback request has expired",
        close: "Close this window",
      },
      error: {
        title: "An error has occurred.",
        caption: "Please try again or contact our support team.",
        close: "Close this window",
      },
    },
    multipleSelect: {
      archiveAll: "Archive all",
      deselectAll: "Deselect all",
    },
  },
};

export default feedbacks;
