const slack = {
  slack: {
    permissions: {
      update:
        "Necesitas actualizar tus permisos para poder usar esta funcionalidad. Tranquil@, te la hacemos fácil:",
      button: "Actualizar",
    },
    channel: {
      select: "Elige tu canal de envío",
    },
  },
};

export default slack;
