const buttons = {
  buttons: {
    create: "Crear",
    edit: "Editar",
    delete: "Eliminar",
    accept: "Aceptar",
    continue: "Continuar",
    cancel: "Cancelar",
    add: "Agregar",
    back: "Volver",
    save: "Guardar",
    saveChanges: "Guardar cambios",
    next: "Siguiente",
    finish: "Finalizar",
    send: "Enviar",
    schedule: "Agendada",
  },
};

export default buttons;
