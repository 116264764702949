const filterData = {
  filterData: {
    overall: "Overall",
    organization: "All the organization",
    noInfo: {
      team: "There is no teams information.",
      segment: "There is no segments information.",
    },
    disabled: {
      activeUsers: "(< 4 active users)",
    },
    sections: {
      all: "All",
      teams: "Teams",
      segments: "Segments",
    },
    periods: {
      "7d": "7 days",
      "30d": "30 days",
      "6m": "6 months",
      "12m": "12 months",
    },
  },
};

export default filterData;
