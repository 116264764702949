const login = {
  login: {
    slackSso: "Log in via Slack",
    googleSso: "Log in via Google",
    msteamsSso: "Log in via Teams",
    title: "Log In",
    user: "Email",
    password: "Password",
    forgotPassword: "Forgot your password?",
    button: "Log in",
    required: "*Required",
    checkErrors: "Check the following:",
    resetPassword: {
      title: "Recover your password",
      button: "Recover password",
      succesful: {
        title: "We are ready",
        checkEmail:
          "Check your email for instructions on how to change your password",
        button: "Log in",
        again: "Receive new instructions for password change",
      },
    },
    changePassword: {
      title: "Create your new password",
      password: "Password",
      confirm: "Confirm your password",
      button: "Change password",
      passwordScores: {
        tooShort: "Too short",
        weak: "Weak",
        insufficient: "Insufficient",
        good: "Good",
        strong: "Strong",
        veryStrong: "Very strong",
      },
      success: {
        title: "The password has been changed successfully",
        login: "Log in",
      },
      failure: {
        invalid: "Invalid token",
        token: "The token to reset the password has expired or is invalid.",
        instructions: "Receive instructions to change your password again",
      },
      manualReset: {
        title: "Change your password",
        explanation:
          "You are now an administrator, please change your account's password",
        newPassword: "New password",
        logoutExplanation:
          "After we confirm the change, you'll be redirected to log in with your new password.",
      },
    },
  },
};

export default login;
