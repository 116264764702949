const login = {
  login: {
    slackSso: "Entrar com Slack",
    googleSso: "Entrar com Google",
    msteamsSso: "Entrar com Teams",
    title: "Entrar na sua conta",
    user: "E-mail",
    password: "Senha",
    forgotPassword: "Esqueceu sua senha?",
    button: "Entrar na sua conta",
    required: "*Obrigatório",
    checkErrors: "Verifique os seguintes erros:",
    resetPassword: {
      title: "Recuperar senha",
      button: "Recuperar senha",
      succesful: {
        title: "Estamos prontos",
        checkEmail:
          "Verifique seu e-mail para obter instruções sobre como alterar sua senha",
        button: "Fazer login",
        again: "Receber instruções novamente para alterar sua senha",
      },
    },
    changePassword: {
      title: "Criar nova senha",
      password: "Senha",
      confirm: "Confirmar senha",
      button: "Alterar senha",
      passwordScores: {
        tooShort: "Muito curta",
        weak: "Fraca",
        insufficient: "Insuficiente",
        good: "Boa",
        strong: "Forte",
        veryStrong: "Muito forte",
      },
      success: {
        title: "Senha alterada com sucesso",
        login: "Fazer login",
      },
      failure: {
        invalid: "Token inválido",
        token: "O token para redefinir a senha expirou ou é inválido.",
        instructions: "Receber instruções novamente para alterar sua senha",
      },
      manualReset: {
        title: "Alterar sua senha",
        explanation:
          "Agora você é um administrador, por favor, altere a senha da sua conta",
        newPassword: "Nova senha",
        logoutExplanation:
          "Após confirmar a alteração, você será redirecionado para fazer login com sua nova senha.",
      },
    },
  },
};

export default login;
