const organizationTeams = {
  organizationTeams: {
    searchTeamPlaceholder: "Buscar equipo",
    searchUserPlaceholder: "Buscar colaborador",
    totalCollaborators: "Total de colaboradores: ",
    totalManagers: "Total de managers",
    totalTeams: "Total de equipos: ",
    teamCreationError: "Error al crear equipo",
    managerAssignmentError: "Error al agregar manager",
    addManagerTitle: "Agregar gerente",
    selectedManager: "Gerente seleccionado",
    assignManager: "Asignar manager",
    teamDeletionError: "Error al eliminar equipo",
    deleteTeamTitle: "Eliminar equipo",
    deleteTeamCaption:
      "¿Seguro que desea eliminar este equipo? Los equipos secundarios serán eliminados",
    deleteLabel: "Eliminar",
    selectAllMember: "Seleccionar todos",
    addMembersError: "Error al agregar miembros",
    addCollaboratorsTitle: "Agregar colaboradores",
    teamLabel: "Equipo",
    teamNamePlaceholder: "Nombre del equipo",
    collaboratorsLabel: "Colaboradores",
    collaboratorsPlaceholder: "Seleccionar colaboradores",
    addCollaboratorsCaption: "Agregar colaboradores",
    moveTeam: "Mover equipo {{team}}",
    moveTeamLabel: "Mover equipo",
    parentTeamLabel: "Equipo padre",
    editTeamError: "Error al editar equipo",
    editTeamTitle: "Editar equipo",
    toEditTeamLabel: "Nombre del equipo",
    editTeamCaption: "Editar equipo",
    importTeamCaption: "Importar equipos",
    importFileExtension: "El formato del archivo debe ser .xlsx",
    importFileAgainCaption: "Cargar archivo nuevamente",
    dropFileCaption: "Arrastre y suelte su archivo aquí o",
    loadFileCaption: "Cargar archivo",
    importCollaboratorsTitle: "Importar colaboradores",
    importCollaboratorsSubtitle:
      "Para invitar a sus colaboradores, cargue un archivo .xlsx con el formato deseado",
    downloadTemplateFile: "Descargar archivo",
    allTeamsTitle: "Todos los equipos",
    teamsTitle: "Equipos",
    editTeamOption: "Editar equipo",
    addManagerOption: "Agregar manager",
    addCollaboratorsOption: "Agregar colaboradores",
    moveToAnotherTeamOption: "Mover a otro equipo",
    moveTeamOption: "Mover equipo",
    deleteTeamOption: "Eliminar equipo",
    deleteUserOption: "Eliminar miembro",
    selectedCollaboratorsPlural: "{{amount}} colaboradores seleccionados",
    selectedCollaboratorsSingular: "{{amount}} colaborador seleccionado",
    moveToAnotherTeamAnchor: "Mover a otro equipo",
    deleteCollaboratorsAnchor: "Eliminar colaboradores",
    deselectAll: "Deseleccionar todos",
    createTeamsAnchor: "Crear equipos",
    fileError:
      "Error: este archivo tiene errores, por favor revíselo y cárguelo nuevamente",
    viewErrorsAnchor: "Ver errores",
    uploadAgainAnchor: "Cargar archivo nuevamente",
    fileWithoutErrorLabel: "Archivo cargado sin errores",
    viewImportedDataAnchor: "Ver datos importados",
    uploadAnotherFileAnchor: "Cargar otro archivo",
    importResultsTitle: "Resultados de la importación",
    importErrorsAmount: "{{amount}} errores encontrados",
    uploadFileAgainCaption: "Cargar nuevamente",
    reviewUploadErrorsCaption:
      "Revise el resultado de su importación para corregir cualquier error.",
    rowCell: "Fila",
    emailCell: "Correo electrónico",
    teamCell: "Equipo",
    roleCell: "Rol",
    nameCell: "Nombre",
    managerCell: "Managers(s)",
    collaboratorsCell: "Colaboradores",
    deleteMemberError: "Error al eliminar miembro",
    deleteSingleMember: "Eliminar {{roleName}}",
    deleteSingleMemberCaption: "Eliminar",
    deleteMemberConfirmation:
      "¿Está seguro de eliminar a {{user}} de {{team}}?",
    deleteMultipleMember: "Eliminar {{amount}} usuarios de este equipo",
    deleteMembersConfirmation:
      "¿Está seguro de eliminar a los usuarios seleccionados de {{team}}?",
    deleteMultipleMemberCaption: "Eliminar",
    moveSingleMemberTitle: "Mover {{user}}",
    moveMemberConfirmation:
      "¿Está seguro de mover a este {{roleName}} de {{team}}?",
    selectTeamLabel: "Seleccionar equipo",
    selectTeamPlaceholder: "Nuevo equipo",
    moveMemberLabel: "Mover",
    moveMemberError: "Error al mover miembro",
    moveMembersError: "Error al mover miembros",
    moveMultipleMembersTitle: "Mover {{amount}} usuarios a otro equipo",
    moveMultipleMembersConfirmation:
      "¿Está seguro de mover a los usuarios seleccionados de {{team}}?",
    employeeRoleName: "Colaborador",
    managerRoleName: "Manager",
    singularCollaborator: "colaborador",
    multipleCollaborators: "colaboradores",
    singularManager: "manager",
    multipleManagers: "managers",
    assignResultsTitle: "Resultados de la asignación de colaboradores",
    assignResultsSubtitle: "Por favor verifique los resultados de su tarea",
    assignmentResultsClose: "Cerrar",
    assigmentSuccessfully: "Asignación de colaborador exitosa",
    assigmentNotPerformed: "Asignación no realizada",
  },
};

export default organizationTeams;
