export default {
  h1: {
    fontWeight: "bold",
    fontSize: "25px",
    letterSpacing: "normal",
  },
  h2: {
    fontWeight: "bold",
    fontSize: "20px",
    letterSpacing: "normal",
  },
  h3: {
    fontWeight: "bold",
    fontSize: "18px",
    letterSpacing: "normal",
  },
  h4: {
    fontSize: "16px",
    letterSpacing: "normal",
    fontWeight: 500,
  },
  h5: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "normal",
  },
  body1: {
    fontWeight: "normal",
    fontSize: "16px",
    color: "#1a051d",
  },
  body2: {
    fontWeight: "normal",
    fontSize: "14px",
    color: "#1a051d",
  },
  button: {
    fontWeight: 600,
    fontSize: "16px",
  },
  caption: {
    fontSize: "13px",
    fontWeight: 600,
  },
  th: {
    fontWeight: 1000,
  },
  fontFamily: ["Montserrat", "sans-serif"].join(","),
};
