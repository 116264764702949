import { GET_USER_SURVEYS_SESSIONS, GET_USER_SURVEY } from "../actions/types";

const initialState = {
  user_surveys_sessions: null,
  user_survey: {
    success: null,
    result: {},
  },
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_SURVEYS_SESSIONS:
      return {
        ...state,
        user_surveys_sessions: action.payload.result,
      };
    case GET_USER_SURVEY: {
      return {
        ...state,
        user_survey: action.payload,
      };
    }
    default:
      return state;
  }
}
