import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const checkUser = (auth, props, Component) => {
  if (!auth.isAuthenticated) return <Redirect to="/" />;
  return <Component {...props} />;
};

const UserPrivateRoute = function ({ component: Component, ...rest }) {
  const auth = useSelector((state) => state.auth);
  return (
    <Route {...rest} render={(props) => checkUser(auth, props, Component)} />
  );
};

UserPrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default UserPrivateRoute;
