const questions = {
  questions: {
    amountOfAnswers: "Número total de respostas: {{answers}}",
    score: "Pontuação média: {{score}}",
    empty: "Não há informações disponíveis. Tente alterar os filtros",
    questionBar: {
      strongNegative: "Fortemente negativo",
      negative: "Negativo",
      neutral: "Neutro",
      positive: "Positivo",
      strongPositive: "Fortemente positivo",
    },
    button: {
      title: "Exportar",
    },
    participationRate: {
      title: "Perguntas",
      metric: "Métrica",
      subMetric: "Submétrica",
      filter: "Equipe / segmento",
      period: "Período",
      cycle: "Ciclo",
      question: "Pergunta",
      results: "Resultados",
      participationNumber: "Nº de respostas",
      participationRate: "%",
      score: "Média",
      noAvailable: "Dados não disponíveis",
      noAnswers: "Não há respostas para esta pergunta",
      noDate: "Sem informações de data",
      date: "Data",
      tooltip:
        "Apenas as equipes com 5 ou mais usuários ativos aparecerão no filtro de equipes",
    },
    modalQuestions: {
      title: "Exportando resultados",
      subTitleCycle: "Selecione um ciclo de medição",
      subTitleTeams: "Incluir resultados da equipe",
      subTitleResults: "Incluir resultados gerais",
      subTitleEmail: "Digite um e-mail",
      tooltipCycle:
        "Selecione um ciclo de medição para exportar seus resultados.",
      tooltipTeams:
        "As equipes selecionadas deverão ter pelo menos 4 participantes durante o ciclo para exportar seus resultados.",
      tooltipResults:
        "Selecione esta opção para incluir uma seção com os resultados gerais da sua organização na exportação.",
      tooltipEmail:
        "Um documento CSV com todos os resultados gerais e dos times selecionados será enviado. Só é possível inserir um endereço de e-mail.",
      alertSuccess: "Foi enviado com sucesso! Isso pode levar alguns minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "É necessário um endereço de e-mail.",
        MISSING_PARAMETERS:
          "Faltam parâmetros necessários. Por favor, entre em contato com o suporte.",
        NO_RESPONSES: "Nenhuma resposta encontrada para esta conta.",
        EMPTY_TEAM: "Uma das equipes selecionadas está vazia.",
        NOT_ENOUGH_USERS:
          "Não há usuários ativos suficientes para gerar um relatório.",
        NO_RESPONSES_TEAM: "Uma das equipes selecionadas não tem respostas.",
        NO_ACTIVE_RESPONSES: "Sem respostas de usuários ativos.",
        NOT_ENOUGH_ACTIVE_USERS:
          "Uma das equipes selecionadas não tem usuários ativos suficientes.",
        INTERNAL_ERROR:
          "Ocorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
      },
    },
  },
};

export default questions;
