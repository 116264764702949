const video = {
  video: {
    notFound: "¡Este video está en construcción!",
    sections: {
      dashboard: "Dashboard",
      users: "Usuarios",
      teams: "Equipos",
      surveys: "Encuestas",
      feedback: "Feedbacks",
      questions: "Preguntas",
      recognitions: "Reconocimientos",
      settings: "Configuración",
      properties: "Propiedades",
      segments: "Segmentos",
      calendar: "Calendario",
      organization_teams: "Equipos",
      "user-feedback": "Feedback",
      performance: "Performance",
    },
  },
};

export default video;
