const teams = {
  teams: {
    group: "Equipo",
    childsTitle: "Equipos Relacionados",
    index: {
      title: "Todos los Equipos",
      clear: "Limpiar Historial",
      search: {
        groups: "Busca tu equipo",
        users: "Busca tu colaborador",
      },
      filter: {
        placeholder: "Selecciona el tipo de colaborador",
        manager: "Manager",
        employee: "Colaborador",
        all: "Todos",
      },
      emptyGroup: {
        title: "Este equipo aún no tiene colaboradores",
        addButton: "Añadir colaboradores",
      },
      groupCard: {
        colaboratorsNumber: "{{number}} colaboradores",
      },
      bodyHeader: {
        colaboratorsNumber: "Total {{number}} colaboradores",
        groupsNumber: "Total {{number}} grupos en este nivel",
      },
      table: {
        name: "Nombre",
        email: "Email",
        rol: "Rol",
        manager: "Manager",
      },
      vertMenuGroup: {
        edit: "Editar equipo",
        add: "Añadir colaboradores",
        move: "Mover a otro grupo",
        delete: "Eliminar equipo",
        removeParent: "Desvincular equipo",
      },
      vertMenuUser: {
        edit: "Editar colaborador",
        moveColaborator: "Mover a otro grupo",
        delete: "Eliminar colaborador",
      },
    },
    modals: {
      removeParent: {
        title: "Desvincular Equipo Padre",
        content:
          "Estás seguro de desvincular el equipo <strong>{{ parentName }}</strong> del equipo <strong>{{ groupName }}<strong>?",
      },
      removeColaborator: {
        title: "Eliminar colaborador",
        content:
          "Estás seguro de eliminar del grupo a <strong>{{ userName }}</strong>?",
      },
      deleteColaborators: {
        title: "Eliminar colaboradores",
        content: "Estás seguro de eliminar los colaboradores?",
      },
      addColaborators: {
        title: "Añadir colaboradores",
        colaborators: "Colaboradores",
        teamName: "Equipo",
        groupPlaceholder: "Buscar equipo",
        colaboratorPlaceholder: "Buscar colaboradores",
        singleColaboratorPlaceholder: "Buscar colaborador",
        required: "Requerido",
        atLeastOne: "Se require al menos un colaborador",
        addButton: "Añadir colaboradores",
      },
      createGroup: {
        title: "Crear nuevo equipo",
        name: "Nombre del equipo",
        parentGroup: "Equipo padre (opcional)",
        createButton: "Crear equipo",
        required: "Requerido",
      },
      move: {
        title: "Mover a otro equipo",
        label: "Nuevo equipo",
      },
    },
    title: "Tus equipos",
    import: "Importar",
    createButton: "Crear",
    table: {
      name: "Nombre",
      users: "Usuarios",
      manager: "Manager",
    },
    message: {
      success: "Grupo {{action}} exitosamente!",
      error: "Hubo un error al {{action}} el grupo, intente nuevamente.",
    },
    addMember: {
      title: "Añadir usuario al equipo",
      emailLabel: "Email Usuario",
    },
    edit: {
      name: "Equipo {{name}}",
      nameLabel: "Nombre",
      searchPlaceholder: "Buscar usuario",
      manager: "Manager",
      managerPlaceholder: "Selecciona un manager",
    },
    create: {
      title: "Crea un nuevo equipo",
      nameLabel: "Nombre del equipo",
      nameHelperText: "Escribe un nombre para el grupo",
      managerLabel: "Manager",
      managerHelperText: "Escoge un administrador del grupo",
    },
    delete: {
      title: "¿Estás seguro de eliminar el grupo <strong>{{name}}</strong>?",
      warning: "Se eliminaran <strong>TODOS</strong> los equipos relacionados",
    },
    bottomInfo: {
      move: "Mover a otro equipo",
      delete: "Eliminar colaborador",
      deleteAll: "Eliminar colaboradores",
      deselect: "Deseleccionar",
      deselectAll: "Deseleccionar todo",
    },
  },
};

export default teams;
