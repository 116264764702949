import axios from "axios";

const slackAxios = axios.create({
  baseURL: process.env.REACT_APP_REQUEST_BASE_URL,
  headers: {
    "X-Request-Source": "slack",
  },
});

export default slackAxios;
