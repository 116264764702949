const feedbacks = {
  feedbacks: {
    title: "Feedback",
    active: "Activas",
    archived: "Archivadas",
    archivedFeedback: {
      see: "Ver feedback",
      delete: "Borrar feedback",
    },
    table: {
      header: {
        received: "Recibido",
        area: "Area",
        type: "Tipo",
        status: "Estado",
        comments: "Comentarios",
      },
    },
    askForFeedback: "Solicitar feedback",
    allMembers: "Todos los colaboradores",
    modal: {
      title: "Solicita feedback a tus equipos",
      formTitle: "Equipo",
      success: "Solicitudes enviadas exitosamente",
      error: "Error enviando la solicitud, inténtelo de nuevo",
    },
    comment: {
      placeholder: "Escriba un comentario",
      success: "Hemos guardado tu comentario",
      close: "Cerrar esta ventana",
      useDescription:
        "Este comentario sera solo para uso interno, no se le enviará ningun comentario sobre el feedback al colaborador",
    },
    statuses: {
      received: "Recibido",
      reviewed: "En Proceso",
      resolved: "Resuelto",
      archived: "Archivado",
    },
    options: {
      archived: "Archivar",
      reopen: "Reabrir",
      delete: "Eliminar",
    },
    types: {
      all: "Todas las opiniones",
      positive: "😀 Positivas",
      neutral: "😐 Neutrales",
      constructive: "🤔 Constructivas",
      complaint: "✋ Ayuda",
    },
    category: {
      choose: "Seleccione la categoria",
      edit: "Editar categoría",
    },
    detail: {
      comments: "Comentarios",
      title: "Detalle de la opinión",
      success: "Feedback actualizado exitosamente!",
      error: "Error actualizando el feedback. Intenta de nuevo",
      required: "Requerido *",
    },
    filters: {
      startText: "Fecha inicio",
      endText: "Fecha fin",
      category: "Categoría",
      type: "Tipo de opinión",
    },
    metadata: {
      positive: "Positivo",
      neutral: "Neutral",
      constructive: "Constructivo",
    },
    user: {
      title: "Hola",
      subTitle: "Queremos conocer tu opinión sobre la compañia.",
      caption:
        "Cuentanos un poco sobre las cosas que te gustaría que mejoren en la compañia y si tienes alguna idea o iniciativa.",
      privacy: "Recuerda tu respuesta se va a mantener 100% anónima",
      opinionPlaceholder: "Escribe aquí tu opinión",
      categoryPlaceholder: "Esta opinión la consideras:",
      send: "Enviar",
      types: {
        positive: "Positiva",
        neutral: "Neutral",
        constructive: "Constructiva",
      },
      success: {
        close: "Cerrar esta ventana",
        title: "Tu opinión ha sido recibida",
        subTitle:
          "La persona encargada estará revisando tu opinión. Todas las opiniones son tomadas en cuenta.",
      },
      expired: {
        title: "Esta solicitud de feedback ha expirado",
        close: "Cerrar esta ventana",
      },
      error: {
        title: "Ha ocurrido un error.",
        caption: "Intenta de nuevo o contacta nuestro equipo de soporte.",
        close: "Cerrar esta ventana",
      },
    },
    multipleSelect: {
      archiveAll: "Archivar todos",
      deselectAll: "Deseleccionar todos",
    },
  },
};

export default feedbacks;
