const imports = {
  import: {
    dragOrselectImage: {
      lightBackground: "light background",
      darkBackground: "dark background",
    },
  },
};

export default imports;
