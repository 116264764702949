import React from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    background: "#1D1F58",
    top: 0,
    left: 0,
  },
  backgroundGradient: {
    background:
      "linear-gradient(180deg, rgba(62, 65, 194, 0) 1.56%, #05064D 82.29%)",
    height: "100%",
    width: "100%",
  },
}));

const LoadingPage = function () {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid className={classes.backgroundGradient} align="center">
        <Grid container justifyContent="center">
          <CircularProgress
            size={100}
            style={{ marginTop: "20%" }}
            color="secondary"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingPage;
