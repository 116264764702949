const submetrics = {
  submetrics: {
    recognitionQuality: "Calidad del reconocimiento",
    recognitionFrequency: "Frecuencia del reconocimiento",
    feedbackQuality: "Calidad del feedback",
    feedbackFrequency: "Frecuencia del feedback",
    organizationTips: "Sugerencias para la organización",
    pride: "Orgullo",
    ambassador: "Embajador",
    values: "Valores",
    visionAndMission: "Visión y misión",
    ethicsAndSocialResponsability: "Ética y responsabilidad social",
    trustBetweenPeers: "Confianza entre compañeros",
    collaborationBetweenPeers: "Colaboración entre compañeros",
    communicationBetweenPeers: "Comunicación entre compañeros",
    managerTrust: "Confianza con el manager",
    managerCommunication: "Comunicación con el manager",
    managerAppraisal: "Valoración del manager",
    compensation: "Compensación",
    roleWithinTheOrganization: "Rol dentro de la organización",
    workplace: "Lugar de trabajo",
    stress: "Estrés",
    personalHealth: "Salud personal",
    autonomy: "Autonomía",
    purpose: "Propósito",
    mastery: "Maestría",
    happinessAtWork: "Felicidad en el trabajo",
    workLifeBalance: "Equilibrio trabajo-vida personal",
  },
};

export default submetrics;
