const filterData = {
  filterData: {
    overall: "General",
    organization: "Toda la organización",
    noInfo: {
      team: "No hay información de equipos.",
      segment: "No hay información de segmentos.",
    },
    disabled: {
      activeUsers: "(< 4 usuarios activos)",
    },
    sections: {
      all: "Todos",
      teams: "Equipos",
      segments: "Segmentos",
    },
    periods: {
      "7d": "7 días",
      "30d": "30 días",
      "6m": "6 meses",
      "12m": "12 meses",
    },
  },
};

export default filterData;
