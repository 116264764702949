const teams = {
  teams: {
    group: "Equipe",
    childsTitle: "Equipes Relacionadas",
    index: {
      title: "Todas as Equipes",
      clear: "Limpar Histórico",
      search: {
        groups: "Pesquisar equipe",
        users: "Pesquisar colaborador",
      },
      filter: {
        placeholder: "Selecione o tipo de colaborador",
        manager: "Gerente",
        employee: "Colaborador",
        all: "Todos",
      },
      emptyGroup: {
        title: "Esta equipe ainda não possui colaboradores",
        addButton: "Adicionar colaboradores",
      },
      groupCard: {
        colaboratorsNumber: "{{number}} colaboradores",
      },
      bodyHeader: {
        colaboratorsNumber: "Total de {{number}} colaboradores",
        groupsNumber: "Total de {{number}} grupos neste nível",
      },
      table: {
        name: "Nome",
        email: "Email",
        role: "Função",
        manager: "Gerente",
      },
      vertMenuGroup: {
        edit: "Editar equipe",
        add: "Adicionar colaboradores",
        move: "Mover para outra equipe",
        delete: "Excluir equipe",
        removeParent: "Desvincular equipe",
      },
      vertMenuUser: {
        edit: "Editar colaborador",
        moveColaborator: "Mover para outra equipe",
        delete: "Excluir colaborador",
      },
    },
    modals: {
      removeParent: {
        title: "Desvincular Equipe Pai",
        content:
          "Tem certeza de que deseja desvincular a equipe <strong>{{ parentName }}</strong> da equipe <strong>{{ groupName }}</strong>?",
      },
      removeColaborator: {
        title: "Excluir colaborador",
        content:
          "Tem certeza de que deseja excluir do grupo o colaborador <strong>{{ userName }}</strong>?",
      },
      deleteColaborators: {
        title: "Excluir colaboradores",
        content: "Tem certeza de que deseja excluir os colaboradores?",
      },
      addColaborators: {
        title: "Adicionar colaboradores",
        colaborators: "Colaboradores",
        teamName: "Equipe",
        groupPlaceholder: "Pesquisar equipe",
        colaboratorPlaceholder: "Pesquisar colaboradores",
        singleColaboratorPlaceholder: "Pesquisar colaborador",
        required: "Obrigatório",
        atLeastOne: "Pelo menos um colaborador é necessário",
        addButton: "Adicionar colaboradores",
      },
      createGroup: {
        title: "Criar nova equipe",
        name: "Nome da equipe",
        parentGroup: "Equipe pai (opcional)",
        createButton: "Criar equipe",
        required: "Obrigatório",
      },
      move: {
        title: "Mover para outra equipe",
        label: "Nova equipe",
      },
    },
    title: "Suas equipes",
    import: "Importar",
    createButton: "Criar",
    table: {
      name: "Nome",
      users: "Usuários",
      manager: "Gerente",
    },
    message: {
      success: "Equipe {{action}} com sucesso!",
      error: "Ocorreu um erro ao {{action}} a equipe, tente novamente.",
    },
    addMember: {
      title: "Adicionar usuário à equipe",
      emailLabel: "Email do usuário",
    },
    edit: {
      name: "Equipe {{name}}",
      nameLabel: "Nome",
      searchPlaceholder: "Pesquisar usuário",
      manager: "Gerente",
      managerPlaceholder: "Selecione um gerente",
    },
    create: {
      title: "Criar uma nova equipe",
      nameLabel: "Nome da equipe",
      nameHelperText: "Digite um nome para o grupo",
      managerLabel: "Gerente",
      managerHelperText: "Selecione um administrador para o grupo",
    },
    delete: {
      title:
        "Tem certeza de que deseja excluir a equipe <strong>{{name}}</strong>?",
      warning: "Todos os grupos relacionados serão <strong>excluídos</strong>",
    },
    bottomInfo: {
      move: "Mover para outra equipe",
      delete: "Excluir colaborador",
      deleteAll: "Excluir colaboradores",
      deselect: "Desmarcar",
      deselectAll: "Desmarcar todos",
    },
  },
};

export default teams;
