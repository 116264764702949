const teams = {
  teams: {
    group: "Team",
    childsTitle: "Teams related",
    index: {
      title: "All Teams",
      clear: "Clear History",
      search: {
        groups: "Search team",
        users: "Search collaborator",
      },
      filter: {
        placeholder: "Select collaborator type",
        manager: "Manager",
        employee: "Employee",
        all: "All",
      },
      emptyGroup: {
        title: "This team have not any collaborator yet",
        addButton: "Add collaborators",
      },
      groupCard: {
        colaboratorsNumber: "{{number}} collaborators",
      },
      bodyHeader: {
        colaboratorsNumber: "Total {{number}} collaborators",
        groupsNumber: "Total {{number}} teams in this level",
      },
      table: {
        name: "Name",
        email: "Email",
        rol: "Rol",
        manager: "Manager",
      },
      vertMenuGroup: {
        edit: "Edit team",
        add: "Add collaborators",
        move: "Move to another team",
        delete: "Delete team",
      },
      vertMenuUser: {
        edit: "Edit collaborator",
        moveColaborator: "Move to another team",
        delete: "Delete collaborator",
      },
    },
    modals: {
      removeParent: {
        title: "Untie Parent Team",
        content:
          "Are you sure untie team <strong>{{ parentName }}</strong> from <strong>{{ groupName }}<strong>?",
      },
      removeColaborator: {
        title: "Delete collaborator",
        content:
          "Are you sure to delete <strong>{{ userName }}</strong> from team?",
      },
      deleteColaborators: {
        title: "Delete collaborators",
        content: "Are you sure to delete these collaborators?",
      },
      addColaborators: {
        title: "Add collaborators",
        addButton: "Add employees",
        colaborators: "Collaborators",
        teamName: "Team",
        groupPlaceholder: "Search team",
        colaboratorPlaceholder: "Search collaborators",
        singleColaboratorPlaceholder: "Search collaborator",
        required: "Required",
        atLeastOne: "Required at least one collaborator",
      },
      createGroup: {
        title: "Create new team",
        name: "Team name",
        parentGroup: "Parent team (optional)",
        createButton: "Create team",
        required: "Required",
      },
      move: {
        title: "Move to another team",
        label: "New team",
      },
    },
    title: "Your teams",
    import: "Import",
    createButton: "Create",
    table: {
      name: "Name",
      users: "Users",
      manager: "Manager",
    },
    message: {
      success: "Team {{action}} successfully!",
      error: "There was an error in the {{action}} team, please try again.",
    },
    addMember: {
      title: "Add user to team",
      emailLabel: "User email",
    },
    edit: {
      name: "Team {{name}}",
      nameLabel: "Name",
      searchPlaceholder: "Search user",
      manager: "Manager",
      managerPlaceholder: "Select a manager",
    },
    create: {
      title: "Create a new team",
      nameLabel: "Team name",
      nameHelperText: "Write your team name",
      managerLabel: "Manager",
      managerHelperText: "Choose a team manager",
    },
    delete: {
      title: "Are you sure to delete this team <strong>{{name}}</strong>?",
      warning:
        "The next action gonna delete <strong>ALL</strong> teams related",
    },
    bottomInfo: {
      move: "Move to another team",
      delete: "Delete collaborator",
      deleteAll: "Delete collaborator",
      deselect: "Deselect",
      deselectAll: "Deselect all",
    },
  },
};

export default teams;
