const organizationTeams = {
  organizationTeams: {
    searchTeamPlaceholder: "Buscar equipe",
    searchUserPlaceholder: "Buscar colaborador",
    totalCollaborators: "Total de colaboradores: ",
    totalManagers: "Gerentes totais",
    totalTeams: "Total de equipes: ",
    teamCreationError: "Erro ao criar equipe",
    managerAssignmentError: "Erro ao adicionar gerente",
    addManagerTitle: "Adicionar gerente",
    selectedManager: "Gerente selecionado",
    assignManager: "Atribuir gerente",
    teamDeletionError: "Erro ao deletar equipe",
    deleteTeamTitle: "Deletar equipe",
    deleteTeamCaption:
      "Tem certeza de que deseja deletar esta equipe? As equipes filhas serão deletadas",
    deleteLabel: "Deletar",
    selectAllMember: "Selecionar todos",
    addMembersError: "Erro ao adicionar membros",
    addCollaboratorsTitle: "Adicionar colaboradores",
    teamLabel: "Equipe",
    teamNamePlaceholder: "Nome da equipe",
    collaboratorsLabel: "Colaboradores",
    collaboratorsPlaceholder: "Selecionar colaboradores",
    addCollaboratorsCaption: "Adicionar colaboradores",
    moveTeam: "Mover equipe {{team}}",
    moveTeamLabel: "Mover equipe",
    parentTeamLabel: "Equipe mãe",
    editTeamError: "Erro ao editar equipe",
    editTeamTitle: "Editar equipe",
    toEditTeamLabel: "Nome da equipe",
    editTeamCaption: "Editar equipe",
    importTeamCaption: "Importar equipes",
    importFileExtension: "O formato do arquivo deve ser .xlsx",
    importFileAgainCaption: "Carregar arquivo novamente",
    dropFileCaption: "Arraste e solte seu arquivo aqui ou",
    loadFileCaption: "Carregar arquivo",
    importCollaboratorsTitle: "Importar seus colaboradores",
    importCollaboratorsSubtitle:
      "Para convidar seus colaboradores, carregue um arquivo .xlsx no formato desejado",
    downloadTemplateFile: "Baixar arquivo",
    allTeamsTitle: "Todas as equipes",
    teamsTitle: "Equipes",
    editTeamOption: "Editar equipe",
    addManagerOption: "Adicionar gerente",
    addCollaboratorsOption: "Adicionar colaboradores",
    moveToAnotherTeamOption: "Mover para outra equipe",
    moveTeamOption: "Mover equipe",
    deleteTeamOption: "Deletar equipe",
    deleteUserOption: "Deletar membro",
    selectedCollaboratorsPlural: "{{amount}} colaboradores selecionados",
    selectedCollaboratorsSingular: "{{amount}} colaborador selecionado",
    moveToAnotherTeamAnchor: "Mover para outra equipe",
    deleteCollaboratorsAnchor: "Deletar colaboradores",
    deselectAll: "Desselecionar todos",
    createTeamsAnchor: "Criar equipes",
    fileError:
      "Erro: este arquivo contém erros, por favor, reveja e faça o upload novamente",
    viewErrorsAnchor: "Ver erros",
    uploadAgainAnchor: "Carregar arquivo novamente",
    fileWithoutErrorLabel: "Arquivo carregado sem erros",
    viewImportedDataAnchor: "Ver dados importados",
    uploadAnotherFileAnchor: "Carregar outro arquivo",
    importResultsTitle: "Resultados da importação",
    importErrorsAmount: "{{amount}} erros encontrados",
    uploadFileAgainCaption: "Carregar novamente",
    reviewUploadErrorsCaption:
      "Revise o resultado da sua importação para corrigir quaisquer erros.",
    rowCell: "Linha",
    emailCell: "E-mail",
    teamCell: "Equipe",
    roleCell: "Cargo",
    nameCell: "Nome",
    managerCell: "Gerente(s)",
    collaboratorsCell: "Colaboradores",
    deleteMemberError: "Erro ao deletar membro",
    deleteSingleMember: "Deletar {{roleName}}",
    deleteSingleMemberCaption: "Deletar",
    deleteMemberConfirmation:
      "Tem certeza de que deseja deletar {{user}} de {{team}}",
    deleteMultipleMember: "Deletar {{amount}} usuários desta equipe",
    deleteMembersConfirmation:
      "Tem certeza de que deseja deletar os usuários selecionados de {{team}}",
    deleteMultipleMemberCaption: "Deletar",
    moveSingleMemberTitle: "Mover {{user}}",
    moveMemberConfirmation:
      "Tem certeza de que deseja mover este {{roleName}} de {{team}}",
    selectTeamLabel: "Selecionar equipe",
    selectTeamPlaceholder: "Nova equipe",
    moveMemberLabel: "Mover",
    moveMemberError: "Erro ao mover membro",
    moveMembersError: "Erro ao mover membros",
    moveMultipleMembersTitle: "Mover {{amount}} usuários para outra equipe",
    moveMultipleMembersConfirmation:
      "Tem certeza de que deseja mover os usuários selecionados de {{team}}",
    employeeRoleName: "Funcionário",
    managerRoleName: "Gerente",
    singularCollaborator: "colaborador",
    multipleCollaborators: "colaboradores",
    singularManager: "gerente",
    multipleManagers: "gerentes",
    assignResultsTitle: "Resultados das tarefas dos colaboradores",
    assignResultsSubtitle: "Verifique os resultados da sua tarefa",
    assignmentResultsClose: "Fechar",
    assigmentSuccessfully: "Atribuição do colaborador bem-sucedida",
    assigmentNotPerformed: "Tarefa não realizada",
  },
};

export default organizationTeams;
