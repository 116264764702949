const metrics = {
  metrics: {
    all: "Todas as categorias",
    commitment: "Compromisso",
    feedback: "Feedback",
    personalGrowth: "Crescimento pessoal",
    recognition: "Reconhecimento",
    managerRelationship: "Relação com o gestor",
    teamwork: "Trabalho em equipe",
    satisfaction: "Satisfação",
    wellness: "Bem-estar",
    withoutMetric: "Sem métrica associada",
    filters: {
      Feedback: "Feedback",
      Compromiso: "Compromisso",
      "Crecimiento personal": "Crescimento pessoal",
      Reconocimiento: "Reconhecimento",
      "Relación con el manager": "Relação com o gestor",
      "Trabajo en equipo": "Trabalho em equipe",
      Satisfacción: "Satisfação",
      Bienestar: "Bem-estar",
      "Todas las categorias": "Todas as categorias",
      null: null,
    },
  },
};

export default metrics;
