import topbar from "./topbar";
import sidebar from "./sidebar";
import users from "./users";
import teams from "./teams";
import buttons from "./buttons";
import surveys from "./surveys";
import configuration from "./configuration";
import feedbacks from "./feedbacks";
import metrics from "./metrics";
import submetrics from "./submetrics";
import adminOnboarding from "./adminOnboarding";
import passwordStrength from "./passwordStrength";
import userOnboarding from "./userOnboarding";
import questions from "./questions";
import login from "./login";
import app from "./app";
import filterData from "./filterData";
import newDashboard from "./newDashboard";
import userParticipation from "./userParticipation";
import recognitions from "./recognitions";
import calendar from "./calendar";
import video from "./video";
import slack from "./slack";
import imports from "./import";
import organizationTeams from "./organizationTeams";
import userFeedback from "./userFeedback";
import reporting from "./reporting";
import common from "./common";

export default {
  ...app,
  ...feedbacks,
  ...topbar,
  ...sidebar,
  ...newDashboard,
  ...recognitions,
  ...metrics,
  ...users,
  ...teams,
  ...surveys,
  ...feedbacks,
  ...buttons,
  ...configuration,
  ...submetrics,
  ...adminOnboarding,
  ...userOnboarding,
  ...passwordStrength,
  ...questions,
  ...login,
  ...filterData,
  ...userParticipation,
  ...calendar,
  ...video,
  ...slack,
  ...imports,
  ...organizationTeams,
  ...userFeedback,
  ...reporting,
  ...common,
};
