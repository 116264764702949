const calendar = {
  calendar: {
    event: {
      newEvent: "Novo evento",
      editEvent: "Editar evento",
      moreEvent: "mais evento",
      moreEvents: "mais eventos",
      createEvent: {
        selectDate: "Data",
        selectHour: "Hora",
        selectMinutes: "Minutos",
      },
      save: "Salvar",
      type: "Tipo de evento",
      tooltipThereIsRecognition:
        "Você só pode enviar reconhecimentos uma vez por mês",
    },
    createdEvent: {
      message: "Evento agendado!",
    },
    updateEvent: {
      message: "Evento atualizado!",
      errorMessage: "erro",
      updateOne: "Este evento",
      updateAll: "Todos os eventos",
      updateOneTooltip: "Aplica apenas à data",
    },
    deleteEvent: {
      message: "Evento excluído!",
      undoDeleted: "Desfazer",
      deleteOne: "Este evento",
      deleteAll: "Todos os eventos",
    },
    toolbar: {
      newEvent: "Novo evento",
      month: "Mês",
      week: "Semana",
      day: "Dia",
      today: "Hoje",
      list: "Lista",
    },
    types: {
      microSurvey: "Microenquete",
      feedback: "Feedback organizacional",
      customSurvey: "Pesquisa personalizada",
      onboarding: "Integração",
      recognitionRequest: "Solicitação de reconhecimento",
      recognitionReport: "Relatório de reconhecimento",
      randomMeeting: "Reunião aleatória",
      questionsReminder: "Lembrete de perguntas",
    },
    recognitions: {
      info: "Os reconhecimentos são enviados apenas uma vez por mês",
    },
    microSurvey: {
      questionsQuantity: "Quantidade de perguntas",
    },
    customSurvey: {
      anonymous: "É anônimo",
      notAnonymous: "Não é anônimo",
    },
    randomMeeting: {
      groupSize: "Tamanho dos grupos",
      concurrence: "Recorrência das reuniões aleatórias",
      everyWeek: "A cada {{ value }} semana",
      everyWeeks: "A cada {{ value }} semanas",
    },
    audience: {
      title: "Público",
      selectTeam: "Selecionar equipe",
      selectSegment: "Selecionar segmento",
      all: "Todos",
      team: "Equipe",
      segment: "Segmento",
      teamSelect: "Selecionar equipes",
      segmentSelect: "Selecionar segmentos",
      tooltipTextAudience:
        "A equipe ou segmento deve ter pelo menos 4 usuários ativos para serem incluídos no público",
    },
    repeatEvent: {
      title: "Repetir evento",
      frequencySelect: "Frequência",
      everyDay: "Todos os dias úteis",
      everyWeek: "Toda semana às {{day}}",
      everyTwoWeeks: "A cada duas semanas às {{day}}",
      everyMonth: "Todo mês na {{week}} {{day}}",
      everyTwoMonths: "Bimestralmente, às {{week}} {{day}}",
      everyYear: "Todo ano na {{week}} {{day}} de {{month}}",
      week: {
        1: "primeira",
        2: "segunda",
        3: "terceira",
        4: "quarta",
        5: "quinta",
      },
      day: {
        1: "segunda-feira",
        2: "terça-feira",
        3: "quarta-feira",
        4: "quinta-feira",
        5: "sexta-feira",
      },
      last: "última",
      custom: {
        title: "Personalizar...",
        repeat: "Repetir a cada",
        week: "semana",
        month: "mês",
        year: "ano",
        weeks: "semanas",
        months: "meses",
        years: "anos",
        itRepeats: "Repete no",
        errorValidate: "Selecione pelo menos um dia",
      },
    },
    sentTo: {
      title: "Enviado para",
      content: "{{sentTo}} colaboradores",
    },
    date: "Data",
  },
};

export default calendar;
