/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const msClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const msTenantId = process.env.REACT_APP_MICROSOFT_TENANT_ID;

export const configureMsAuth = (redirectUrl) => ({
  auth: {
    clientId: msClientId,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: redirectUrl,
    postLogoutRedirectUri: "/",
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
});

export const msalConfig = {
  auth: {
    clientId: msClientId,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: "/admin/teams-connect",
    postLogoutRedirectUri: "/",
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read.All",
    "Team.ReadBasic.All",
    "TeamSettings.Read.All",
    "Group.ReadWrite.All",
    "GroupMember.Read.All",
    "GroupMember.ReadWrite.All",
    "Group.ReadWrite.All",
    "Directory.Read.All",
    "TeamsAppInstallation.ReadWriteForUser",
    "ChannelMessage.Send",
    "Chat.Create",
  ],
};
