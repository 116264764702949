const submetrics = {
  submetrics: {
    recognitionQuality: "Recognition quality",
    recognitionFrequency: "Recognition frequency",
    feedbackQuality: "Feedback quality",
    feedbackFrequency: "Feedback frequency",
    organizationTips: "Organization suggestions",
    pride: "Pride",
    ambassador: "Ambassador",
    values: "Values",
    visionAndMission: "Vision and mission",
    ethicsAndSocialResponsability: "Ethics and social responsability",
    trustBetweenPeers: "Trust between peers",
    collaborationBetweenPeers: "Collaboration between peers",
    communicationBetweenPeers: "Communication between peers",
    managerTrust: "Trust towards the manager",
    managerCommunication: "Communication towards the manager",
    managerAppraisal: "Manager's appraisal",
    compensation: "Compensation",
    roleWithinTheOrganization: "Role within the organization",
    workplace: "Workplace",
    stress: "Stress",
    personalHealth: "Personal health",
    autonomy: "Autonomy",
    purpose: "Purpose",
    mastery: "Mastery",
    happinessAtWork: "Hapiness at work",
    workLifeBalance: "Work life balance",
  },
};

export default submetrics;
