const userFeedback = {
  userFeedback: {
    sent: "Feedbacks enviados",
    roles: {
      all: "Todos",
      employee: "Funcionário",
      manager: "Manager",
    },
    search: {
      notFound: "Não foi encontrado nenhum usuário com esse nome.",
    },
    header: {
      avatar: "",
      name: "Pessoa",
      sent: "Enviados",
      received: "Recebidos",
      pending: "Pendentes",
      role: "Função",
      last: "Último feedback",
      avg: "Feedback média",
    },
    evaluations: {
      veryLow: "Não atende às expectativas",
      low: "Atende parcialmente às expectativas",
      medium: "Atende às expectativas",
      high: "🚀 Excede as expectativas",
      veryHigh: "🦄 É um referente na indústria",
    },
    detailsModal: {
      searchPlaceholder: "Procurar pessoa",
      segment: "Segmento: ",
      average: "média",
      commentTitle: "Comentário",
      comment: "Comment: ",
      start: "Start: ",
      stop: "Stop: ",
      continue: "Continue: ",
      sent: "Total enviado: ",
      pending: "Total pendiente:",
      received: "Total recibido:",
      tooltip:
        "Apenas será possível visualizar os feedbacks que tenham sido avaliados utilizando uma escala numérica de 1 a 5.",
    },
    exportModal: {
      title: "Exportando Feedbacks",
      exportButton: "Exportar",
      startDatePicker: "Desde:",
      endDatePicker: "Até:",
      endDatePlaceholder: "Selecione uma data de início",
      addTeams: "Adicionar equipes",
      addProperties: "Adicionar propriedades",
      emailLabel: "Digite um e-mail:",
      emailPlaceholder: "exemplo@email.com",
      sendButton: "Enviar",
      alertSuccess: "Foi enviado com sucesso! Isso pode levar alguns minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "É necessário um endereço de e-mail.",
        MISSING_PARAMETERS:
          "Faltam parâmetros necessários. Por favor, entre em contato com o suporte.",
        NO_FEEDBACKS: "Nenhum feedback encontrado para esta conta.",
        INVALID_DATE: "Data inválida.",
        NO_FEEDBACKS_IN_DATERANGE:
          "Nenhum feedback encontrado no intervalo de datas.",
        INTERNAL_ERROR:
          "Ocorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
      },
    },
  },
};

export default userFeedback;
