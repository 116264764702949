import { SET_CUSTOM_IMAGES } from "../actions/types";

const initialState = {
  current: [],
};

export default function recognitionImageReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_IMAGES:
      return {
        current: action.payload.newRecognitionImage,
      };
    default:
      return state;
  }
}
