const buttons = {
  buttons: {
    create: "Create",
    accept: "Accept",
    continue: "Continue",
    cancel: "Cancel",
    add: "Add",
    back: "Back",
    save: "Save",
    saveChanges: "Save changes",
    next: "Next",
    finish: "Finish",
    send: "Send",
    schedule: "Schedule",
  },
};

export default buttons;
