import { OPEN_VIDEO, CLOSE_VIDEO } from "../actions/types";

const initialState = {
  open: false,
  url: null,
};

export default function videoReducers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_VIDEO:
      return {
        payload,
      };
    case CLOSE_VIDEO:
      return {
        ...payload,
        url: null,
      };
    default:
      return state;
  }
}
