const submetrics = {
  submetrics: {
    recognitionQuality: "Qualidade do Reconhecimento",
    recognitionFrequency: "Frequência do Reconhecimento",
    feedbackQuality: "Qualidade do Feedback",
    feedbackFrequency: "Frequência do Feedback",
    organizationTips: "Dicas para a Organização",
    pride: "Orgulho",
    ambassador: "Embaixador",
    values: "Valores",
    visionAndMission: "Visão e Missão",
    ethicsAndSocialResponsability: "Ética e Responsabilidade Social",
    trustBetweenPeers: "Confiança entre Colegas",
    collaborationBetweenPeers: "Colaboração entre Colegas",
    communicationBetweenPeers: "Comunicação entre Colegas",
    managerTrust: "Confiança com o Gestor",
    managerCommunication: "Comunicação com o Gestor",
    managerAppraisal: "Avaliação do Gestor",
    compensation: "Compensação",
    roleWithinTheOrganization: "Papel na Organização",
    workplace: "Ambiente de Trabalho",
    stress: "Estresse",
    personalHealth: "Saúde Pessoal",
    autonomy: "Autonomia",
    purpose: "Propósito",
    mastery: "Mestre",
    happinessAtWork: "Felicidade no Trabalho",
    workLifeBalance: "Equilíbrio entre Trabalho e Vida Pessoal",
  },
};

export default submetrics;
