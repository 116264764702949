const app = {
  infoModal: {
    message: {
      error:
        "Ha ocurrido un error. Intente nuevamente, si el problema persiste contáctese con nosotros",
    },
  },
  newVersion: {
    message: "Nueva versión disponible",
    action: "Actualizar",
  },
};

export default app;
