const topbar = {
  topbar: {
    conditions: "Terms and conditions",
    language: "Language",
    logout: "Logout",
    polite: "Privacy policy",
    settings: "Settings",
    userType: {
      admin: "Admin",
      support: "Support",
    },
    copyToClipboard: "Copied to clipboard",
    demoMessage: {
      oneDay: "You have {{remainingDays}} day of free trial remaining",
      days: "You have {{remainingDays}} days of free trial remaining",
      hours: "You have {{remainingHours}} hours of free trial remaining",
      finish: "Your free trial is about to end!",
    },
  },
};

export default topbar;
