const userOnboarding = {
  userOnboarding: {
    chooseLanguage: {
      title: "Escolha seu idioma",
    },
    welcomeMessage: {
      title: "Bem-vindo ao Burnout-Zero!",
      caption:
        "Construir um local de trabalho melhor é responsabilidade de todos. Vamos participar!",
      anonymous: "Suas respostas são 100% anônimas",
      letsStart: "Vamos lá",
    },
    pendingMessage: {
      title: "Bem-vindo(a) a bordo! ⚓",
      caption:
        "Conte-nos sobre sua experiência nesses primeiros dias e nos ajude a criar o melhor ambiente de trabalho.",
    },
    onboardingMessage: {
      title: "Já se passou um mês! Como você tem se sentido? 🔎",
      caption:
        "Conte-nos sobre seu processo de integração e como você está se preparando para o sucesso em sua organização.",
    },

    subtitles: {
      multipleChoice: "Selecione uma opção",
      draggableScale:
        "Onde 1 estrela significa {{lowValue}} e 5 significa {{highValue}}",
      opinionScale:
        "Onde 0 significa {{lowValue}} e 10 significa {{highValue}}",
      text: "Deixe seu comentário",
      default: "Selecione uma opção",
    },
    starOption: {
      labels: {
        feedback: {
          first: "Absolutamente não",
          second: "Não",
          third: "Apenas razoável",
          fourth: "Sim",
          fifth: "Absolutamente",
        },
        peersRelationship: {
          first: "De forma alguma",
          second: "Não",
          third: "Neutro",
          fourth: "Sim",
          fifth: "Absolutamente",
        },
        onboardingSurvey: {
          first: "Nada bem",
          second: "Mal",
          third: "Neutro",
          fourth: "Bem",
          fifth: "Absolutamente bem",
        },
      },
    },
    faceOption: {
      labels: {
        0: "Discordo fortemente",
        1: "Discordo",
        2: "Neutro",
        3: "Concordo",
        4: "Concordo fortemente",
      },
    },
    stepperButtons: {
      next: "Próximo",
      finish: "Concluir",
    },
    acceptOur: "Ao concluir, você está aceitando nossos",
    termsAndConditions: "termos e condições e política de privacidade",
    finishMessage: {
      title: "Obrigado pelas suas respostas!",
      subTitle:
        "Apenas mais um passo. Dessas 10 métricas, diga-nos quais são as 3 que você considera mais importantes em seu trabalho",
      finishButton: "Concluir",
      finalMessage:
        "Agradecemos suas respostas, você já faz parte do Burnout-Zero!",
      closeMessage: "Você pode fechar esta janela agora",
      closeButton: "Fechar esta janela",
    },
    comment:
      "Você poderia nos dar sua opinião sobre como melhorar? Seu feedback é valioso para nós",
    error: "Este convite já expirou",
  },
};

export default userOnboarding;
