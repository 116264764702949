const imports = {
  import: {
    dragOrselectImage: {
      lightBackground: "fondo claro",
      darkBackground: "fondo oscuro",
    },
  },
};

export default imports;
