const userParticipation = {
  user_participation: {
    hello: "Hola",
    title: "¡Ha llegado el momento de responder tus preguntas pendientes!",
    beginTitle: "Empezar",
    expired: "El token ya expiró o no ha sido encontrado",
    alreadyUsed:
      "Está encuesta ya fue respondida, por favor espera la siguiente con las mismas ansias que esperas el fin de semana",
    unhandledError:
      "Se ha producido un error. Vuelve a intentarlo, si el problema persiste contacta con nosotros",
    empty: "No hay preguntas pendientes",
    close: "Close this window",
    success_title: "Tus respuestas han sido guardadas exitosamente",
  },
};

export default userParticipation;
