const metrics = {
  metrics: {
    all: "All Categories",
    commitment: "Commitment",
    feedback: "Feedback",
    personalGrowth: "Personal growth",
    recognition: "Recognition",
    managerRelationship: "Relationship with manager",
    teamwork: "Teamwork",
    satisfaction: "Satisfaction",
    wellness: "Well-being",
    withoutMetric: "No category",
    filters: {
      Feedback: "Feedback",
      Commitment: "Compromiso",
      "Personal growth": "Crecimiento personal",
      Recognition: "Reconocimiento",
      "Relationship with manager": "Relación con el manager",
      Teamwork: "Trabajo en equipo",
      Satisfaction: "Satisfacción",
      "Well-being": "Bienestar",
      "All Categories": "Todas las categorias",
      null: null,
    },
  },
};

export default metrics;
