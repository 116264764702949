const video = {
  video: {
    notFound: "This video is under construction!",
    sections: {
      dashboard: "Dashboard",
      users: "Users",
      teams: "Teams",
      surveys: "Surveys",
      feedback: "Feedbacks",
      questions: "Questions",
      recognitions: "Recognitions",
      settings: "Settings",
      properties: "Properties",
      segments: "Segments",
      calendar: "Calendar",
      organization_teams: "Teams",
      user_feedback: "Feedback",
      performance: "Performance",
    },
  },
};

export default video;
